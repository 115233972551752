<script>
  import client from "../../../client";
  import { onMount } from 'svelte';
  import { convertDateTime } from "../../../utils/dates";
  import { validateEmail } from "../../../utils/strings";
  import { category } from "../../../utils/race";
  import Select from 'svelte-select';
  import * as xlsx from 'xlsx';
  import onScan from "onscan.js";

  let upload_from = "Entry Time";
  let upload_list = ["Entry Ninja","Entry Time","Race Pass"];
  let files = [];
  let filename = "";
  let athletes = 0;
  let item_loading = false;
  let events_list = [];
  let selected_event = "";
  let activities = [];
  let upload_errors = [];
  let lines = [];
  let entriesText = "Entries";

  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    listItems();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  async function listItems() {
      item_loading = true;
      let items = await client.service('events').find({
        query: {
          $sort: { start_date: 0 },
          $limit: 20
        }
      });
      
      if (items.total > 0) {
        events_list = [];
        items.data.forEach(i => {
          events_list.push({value:i.id, label: i.name +" [ "+ i.venue_name +" ] " + convertDateTime(i.start_date, 2), activities: i.activities})
        });
      } else {
        events_list = [];
      }
      item_loading = false;
  }

  // function findActivity(activity) {
  //   activity = activity + "";
  //   //Try match on whole name first.
  //   for (let index = 0; index < activities.length; index++) {
  //     const act = activities[index];
  //     if (activity == act.name) {
  //       return act.id;
  //     }
  //   }
  //   //If exact match not found use distance.
  //   for (let index = 0; index < activities.length; index++) {
  //     const act = activities[index];
  //     if (activity.indexOf(" ") > 0) {
  //       if (activity.split(" ")[0] == act.name.split(" ")[0]) {
  //         return act.id;
  //       }
  //     // }
  //     // else if (activity == act.name.split(" ")[0].replaceAll("km","")) {
  //     //   return act.id;
  //     // }
  //   }
  //   return -1;
  // }

  function findActivity(activity) {
    //Try match on whole name first.
    for (let index = 0; index < activities.length; index++) {
      const act = activities[index];
      if (activity == act.name) {
        return act.id;
      }
    }
    //If exact match not found use distance.
    for (let index = 0; index < activities.length; index++) {
      const act = activities[index];
      if (activity.split(" ")[0] == act.name.split(" ")[0]) {
        return act.id;
      }
    }
    return -1;
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  function dateWithoutTimezone(date) {
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return withoutTimezone;
  };

  $: if (files.length) {
    parseExcel(files[0]);
  }

  function parseExcel(file) {

    // console.log(file);
    // console.log(upload_from);
    upload_errors = [];
    athletes = 0;
    filename = file.name;
    const reader = new FileReader();
    reader.onload = function(e) {
      item_loading = true;
      const result = e.target.result;

      // Entry Time
      if (upload_from.value == "Entry Time") {
        const workbook = xlsx.read(result, {
          type: 'binary'
        });
        workbook.SheetNames.forEach((sheetName) => {
          const rowObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          for (let i = 0; i < rowObject.length; i++) {
            try {
              const element = rowObject[i];
              if (element['EntryId'] != "Totals:") {
                // console.log(element);
                var line = {
                  index: i + 1,
                  name: "",
                  surname: "",
                  activity_id: "",
                  id_number: "",
                  gender: "",
                  age: 0,
                  event_id: selected_event.value,
                  race_number: "",
                  not_paid: false,
                  email: "",
                  mobile: "",
                  paid_method: "none",
                  paid_amount: "0",
                  comp: 0,
                  status: "",
                  athlete_id: -1,
                  dob: ""
                };   
                line.name = element["FirstName"].trim();
                line.surname = element["LastName"].trim();
                line.activity_id = findActivity(element["EventItemName"].trim());
                line.id_number = element["IDNumber"].trim();
                line.paid_amount = element["TotalAmountPaid"];
                if (element["Gender"].trim() === "M") line.gender = "Male";
                else line.gender = "Female";
                line.age = element["AgeOnRaceDay"].trim();
                if (element["Paid"].trim() == "No") {
                  line.not_paid = true;
                  line.paid_method = "none";
                } else {
                  line.paid_method = "online";
                  line.not_paid = false;
                }
                let importEmail = element["Email"];
                if (importEmail) {
                  importEmail = importEmail.replaceAll(" ","");
                }
                if (
                  importEmail != "" &&
                  validateEmail(importEmail)
                ) {
                  line.email = importEmail;
                }
                if (element["Cell"].trim() != "") {
                  line.mobile = element["Cell"].trim();
                }
                line.dob = element["DateOfBirth"];

                if (line.activity_id != -1) {
                  lines.push(line);
                  athletes++;
                } else {
                  upload_errors.push({line:(i+1),error: "Activity not found : "+element["EventItemName"].trim()});
                }
                
              }
            } catch(err){
              console.error(err);
              upload_errors.push({line:(i+1),error: err.message});
            }
          }
          // items.set(rowObject);
        });
      } // Entry Time
      else if (upload_from.value == "Entry Ninja") { // Entry Ninja
        // ID Number | First Name	| Last Name	| Gender	| Date of Birth |	Age at Event	| Email |	Mobile |	Class	| Fully Paid	| Entry Amount	
        const workbook = xlsx.read(result, {
          type: 'binary',
          cellDates: true,
          cellNF: false,
          cellText: false
        });
        workbook.SheetNames.forEach((sheetName) => {
          const rowObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          for (let i = 0; i < rowObject.length; i++) {
            try {
              const element = rowObject[i];
              
                // console.log(element);
                var line = {
                  index: i + 1,
                  name: "",
                  surname: "",
                  activity_id: "",
                  id_number: "",
                  gender: "",
                  age: 0,
                  event_id: selected_event.value,
                  race_number: "",
                  not_paid: false,
                  email: "",
                  mobile: "",
                  paid_method: "none",
                  paid_amount: "0",
                  comp: 0,
                  status: "",
                  athlete_id: -1,
                  dob: ""
                };   
                line.name = element["First Name"].trim();
                line.surname = element["Last Name"].trim();
                line.activity_id = findActivity(element["Class"].trim());
                line.id_number = (element["ID Number"]?element["ID Number"].trim():"");
                line.paid_amount = element["Entry Amount"];
                if (element["Gender"].trim() === "M") line.gender = "Male";
                else line.gender = "Female";
                line.age = element["Age at Event"];
                if (element["Fully Paid"].trim() == "No") {
                  line.not_paid = true;
                  line.paid_method = "none";
                } else {
                  line.paid_method = "online";
                  line.not_paid = false;
                }
                let importEmail = element["Email"];
                if (importEmail) {
                  importEmail = importEmail.replaceAll(" ","");
                }
                if (
                  importEmail != "" &&
                  validateEmail(importEmail)
                ) {
                  line.email = importEmail;
                }
                if (element["Mobile"].trim() != "") {
                  line.mobile = element["Mobile"].trim();
                }
                line.dob = dateWithoutTimezone(element["Date of Birth"]);

                if (line.activity_id != -1 && line.id_number != "") {
                  lines.push(line);
                  athletes++;
                } else {
                  if (line.activity_id == -1) {
                    upload_errors.push({line:(i+1),error: "Activity not found : "+element["Class"].trim()});
                  }
                  if (line.id_number == "") {
                    upload_errors.push({line:(i+1),error: "No ID Number Loaded : "+line.name+" "+line.surname});
                  }
                }
            } catch(err){
              console.error(err);
              upload_errors.push({line:(i+1),error: err.message});
            }
          }
          // items.set(rowObject);
        });
      } // Entry Ninja
      else if (upload_from.value == "Race Pass") { // Race Pass
        // Purchase Date | First Name | Last Name | Date of Birth | Email |	Gender | ID/Passport | Phone | Distance | Race Event Cost | Promo Code |	Discount Amount |	Commission | Net Revenue | Organizer
        const workbook = xlsx.read(result, {
          type: 'binary',
          cellDates: true,
          cellNF: false,
          cellText: false
        });
        workbook.SheetNames.forEach((sheetName) => {
          const rowObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          for (let i = 0; i < rowObject.length; i++) {
            try {
              const element = rowObject[i];
              if (element['EntryId'] != "Totals:") {
                // console.log(element);
                var line = {
                  index: i + 1,
                  name: "",
                  surname: "",
                  activity_id: "",
                  id_number: "",
                  gender: "",
                  age: 0,
                  event_id: selected_event.value,
                  race_number: "",
                  not_paid: false,
                  email: "",
                  mobile: "",
                  paid_method: "none",
                  paid_amount: "0",
                  comp: 0,
                  status: "",
                  athlete_id: -1,
                  dob: ""
                };   
                line.name = element["First Name"].trim();
                line.surname = element["Last Name"].trim();
                line.activity_id = findActivity(element["Distance"]);
                line.id_number = element["ID/Passport"];
                line.paid_amount = element["Race Event Cost"];
                line.gender = element["Gender"].trim();
                line.age = getAge(element["Date of Birth"]);
                // if (element["Paid"].trim() == "No") {
                //   line.not_paid = true;
                //   line.paid_method = "none";
                // } else {
                  line.paid_method = "online";
                  line.not_paid = false;
                // }
                let importEmail = element["Email"];
                if (importEmail) {
                  importEmail = importEmail.replaceAll(" ","");
                }
                if (
                  importEmail != "" &&
                  validateEmail(importEmail)
                ) {
                  line.email = importEmail;
                }
                if (element["Phone"] != "") {
                  line.mobile = element["Phone"];
                }
                // line.dob = element["Date of Birth"].toISOString().slice(0,10).replace(/-/g,"");
                line.dob = element["Date of Birth"];

                if (line.activity_id != -1) {
                  lines.push(line);
                  athletes++;
                } else {
                  upload_errors.push({line:(i+1),error: "Activity not found : "+element["EventItemName"].trim()});
                }
              }
            } catch(err){
              console.error(err);
              upload_errors.push({line:(i+1),error: err.message});
            }
          }
          // items.set(rowObject);
        });
      } // Race Pass
      else {
        alert("Format Not Found");
      }

      upload_errors = upload_errors;
      lines = lines;
      // console.log(lines);
      item_loading = false;
    };
    reader.onerror = function(e) {
      console.error(e);
    };
    reader.readAsBinaryString(file);
  }

  function selected() {
    activities = JSON.parse(selected_event.activities);
  }

  async function upload() {
    try {
      upload_errors = [];
      athletes = 0;
      item_loading = true;
      for (let index = 0; index < lines.length; index++) {
        const line = lines[index];
        let athlete = {total:0};
        if (line.id_number != "" && line.id_number != undefined) {
          athlete = await client.service('athlete').find({
            query: {
              id_number: line.id_number,
              $limit: 1,
            }
          });  
        }
        let error = false;
        if (athlete.total > 0)   {
          try {
            let status = await client.service('athlete').patch(athlete.data[0].id,{
              name : line.name,
              surname : line.surname,
              id_number : line.id_number,
              age : line.age,
              gender : line.gender,
              email : line.email,
              mobile : line.mobile,
            });
            line.athlete_id = athlete.data[0].id;
            line.card_number = athlete.data[0].card_number;
            // console.log("Updated athlete : ", line.name + " " + line.surname);
          } catch(err2) {
            console.error(err2);
            line.status = "Error : Updating Athlete";
            error = true;
            upload_errors.push({line:(index+1), status: "Update Athlete", error: err2.message});
          }
        } else {
          try {
            let status = await client.service('athlete').create({
              name : line.name,
              surname : line.surname,
              id_number : line.id_number,
              age : line.age,
              gender : line.gender,
              email : line.email,
              mobile : line.mobile,
              dob : line.dob,
            });
            line.athlete_id = status.id;
            // console.log("Created Athlete : ", line.name + " " + line.surname);
          } catch(err2) {
            console.error(err2);
            line.status = "Error : Creating Athlete";
            upload_errors.push({line:(index+1), status:"Create Athlete", error: err2.message});
            error = true;
          }
        }
    
        if (!error) {
          try {
            // console.log("Adding To Race : ", line);
            let status = await client.service('race').create({
              event_id : line.event_id,
              activity_id : line.activity_id,
              athlete_id : line.athlete_id,
              race_number : (line.card_number?line.card_number:"PRE-"+line.id_number),
              gender : line.gender,
              age : line.age,
              category : category(line.age),
              pre_entry : true,
              paid_method : line.paid_method,
              paid_amount : line.paid_amount,
              not_paid : line.not_paid,
              status : "pre-entry",
            });
            line.status = "Registered";
            ++athletes;
            // console.log("Race Added : ", line);
          } catch(err2) {
            console.error(err2);
            line.status = "Error : Adding Athlete To Race";
            if (err2.message.includes("race_event_id_race_number_unique") || err2.message.includes("race_event_id_athlete_id_unique")) {
              upload_errors.push({line:(index+1),status:"Add Athlete To Race", error: "Duplicate Entry"});
            } else {
              upload_errors.push({line:(index+1),status:"Add Athlete To Race", error: err2.message});
            }
            error = true;
          }
        }
      }
      item_loading = false;
      entriesText = "Entries Uploaded";
    } catch (err) {
      console.error(err);
    }
  }

</script>
<div class="columns is-multiline">
  <div class="column is-full">
<div class="box">
  <span class="tag is-white is-large">Upload Pre-entries</span>
  {#if item_loading}
    <progress class="progress is-primary" max="100">30%</progress>
  {:else}
    <div class="field" style="margin-top:15px;">
      <label class="label" for="">Select Format</label>
      <div class="control">
        <div class="select is-fullwidth">
          <Select items={upload_list} bind:selectedValue={upload_from} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Import Format"></Select>
        </div>
      </div>
    </div>
    <div class="field" style="margin-top:15px;">
      <label class="label" for="">Select Event</label>
      <div class="control">
        <div class="select is-fullwidth">
          <Select items={events_list} bind:selectedValue={selected_event} on:select={() => selected()} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event"></Select>
        </div>
      </div>
    </div>
    <div class="file has-name is-fullwidth">
      <label class="file-label">
        <input
        class="file-input"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      bind:files>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            Choose a file…
          </span>
        </span>
        {#if filename}
        <span class="file-name">
          {filename}
        </span>
        {/if}
      </label>
    </div>
    {#if upload_errors.length > 0}
    <div class="notification is-light is-danger" style="margin-top:15px;">
      <ul>
      {#each upload_errors as err}
        <li>Line : {err.line} : {err.error}</li>   
      {/each}
      </ul>
    </div>
    {/if}  
    
    {#if filename != ""}
      <div style="margin-top:15px;">
      <span class="tag is-success is-large">{entriesText} : {athletes}</span>
      </div>
    {/if}
    
    {#if athletes > 0}
      <div style="margin-top:15px;">
        <button type="button" class="button is-primary" on:click={() => upload()}>Upload</button>
      </div>
    {/if}
  {/if}
</div>
</div>
</div>