<script>
  import { onMount } from "svelte";

  let date = new Date();
  $: hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  $: min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  $: sec = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  onMount(() => {
    const interval = setInterval(() => {
      date = new Date();
    }, 1000);
  });
</script>
<style>
  .clockDisplay {
    font-family: "Orbitron";
    width: 100%;
  }
</style>
<div style="width: 100%">
  <span class="tag is-medium is-link clockDisplay">
    <table border="0" width="100%" style="text-align: center">
      <tr>
        <td style="width: 30%">{hour}</td>
        <td style="width: 5%">:</td>
        <td style="width: 30%">{min}</td>
        <td style="width: 5%">:</td>
        <td style="width: 30%">{sec}</td>
      </tr>
    </table>
  </span>
</div>
