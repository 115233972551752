<script>
  import ItemMenu from "../../../components/Side_Menu.svelte";
  import client from "../../../client";
  import { onMount } from 'svelte';
  import SvelteTable from "svelte-table";
  import Select from 'svelte-select';
  import { convertDateTimeHTML5 } from "../../../utils/dates";
  import onScan from "onscan.js";
  
  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    listChallenges();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  //Challenges
  let challenge;
  let challenge_list = [];
  let selected_data = [];
  //Search Athlete
  let search = "";
  //Search List Variables / Functions
  let search_data = [];
  let search_total = 0;
  let search_limit = 20;
  let search_skip = 0;
  let search_pages = 0;
  let search_page = 0;
  
  async function checkEnter(e) {
    if(e.keyCode === 13){
        e.preventDefault(); // Ensure it is only this code that runs
        listSearchItems();
    }
  }

  async function listChallenges() {
    item_loading = true;
    let items = await client.service('challenge').find({
      query: {
        enabled: true,
        $select: [ 'id', 'name' ],
        $sort: { name: 1 },
        $limit: 500
      }
    });
    // console.log(items);
    challenge_list = [];
    items.data.forEach(i => {
      challenge_list.push({value:i.id, label: i.name})
    });
    item_loading = false;
  }

  //Page Data
  let show = "list";
  let item = 'Challenge';
  let item_message;
  let item_message_type = 'info';
  let item_loading = true;
  
  // List Variables / Functions
  let items_data = [];
  let items_total = 0;
  let challenge_data;

  async function updateAthletes() {
    try {
        let athletes = (challenge_data.athletes?challenge_data.athletes:"");
        let athlete_split = [];
        if (athletes != "") {
          athlete_split = athletes.split(",");
        }
        selected_data.forEach(sd => {
          if (athletes != "") {
            if (!athlete_split.includes(sd.id.toString())) {
              athletes = athletes + "," + sd.id;
            }
          } else {
            athletes = sd.id;
          }
        });
        let status = await client.service('challenge').update(challenge.value,{
          name:challenge_data.name,
          enabled:challenge_data.enabled,
          start:convertDateTimeHTML5(challenge_data.start,2),
          end:convertDateTimeHTML5(challenge_data.end,2),
          athletes : athletes,
        });
        await listItems();
        show = 'list';
        item_message = "Athletes updated successfully";
        item_message_type = "success";
        item_loading = false;
        selected_data = [];
        search = "";
        search_data = [];
      } catch(err) {
        item_message = err;
        item_message_type = "danger";
        item_loading = false;
      }
  }

  async function listItems() {
      item_loading = true;
      let challengeData = await client.service('challenge').find({
        query: {
          id: challenge.value,
        }
      });
      // console.log(challengeData);
      if (challengeData.total > 0) {
        challenge_data = challengeData.data[0];
        let athletes = challengeData.data[0].athletes.split(",");
        // console.log(athletes);
        let items = await client.service('athlete').find({
          query: {
            id: {
              $in: athletes
            },
            $sort: { name: 1, surname: 1 },
          }
        });
        // console.log(items);
        items_data = items.data;
        items_total = items.total;
      } else {
        items_data = "";
      }
      item_loading = false;
    
  }

function selectItem(id, name, surname, age, gender) {
  const found = selected_data.findIndex(obj => obj.id === id);
  if (found == -1) {
    selected_data.push({id:id,name:name,surname:surname,age:age,gender:gender});
    selected_data.sort(function (name1, name2) {
      if (name1.name > name2.name) return 1;
      if (name1.name < name2.name) return -1;
    });
    selected_data = selected_data;
  }
}

function removeItem(id) {
  const found = selected_data.findIndex(obj => obj.id === id);
  if (found > -1) {
    selected_data.splice(found,1);
    selected_data = selected_data;
  }
}

async function removeItemFromDB(id) {
  try {
    let athletes = challenge_data.athletes.split(",");
    const found = athletes.findIndex(obj => obj === id.toString());
    if (found > -1) {
      item_loading = true;
      athletes.splice(found,1);
      let status = await client.service('challenge').update(challenge.value,{
              name:challenge_data.name,
              enabled:challenge_data.enabled,
              start:convertDateTimeHTML5(challenge_data.start,2),
              end:convertDateTimeHTML5(challenge_data.end,2),
              athletes : athletes.join(),
            });
      await listItems();
      show = 'list';
      item_message = "Athletes updated successfully";
      item_message_type = "success";
      item_loading = false;
      selected_data = [];
      search = "";
      search_data = [];
    }
  } catch(err) {
    item_message = err;
    item_message_type = "danger";
    item_loading = false;
  }
}

function paginate(page) {
  if (page < 1)
    page = 0;
  if (page >= search_pages)  
    page = search_pages-1;
    search_skip = (page*search_limit);
  listSearchItems();
  search_page = page;
}

async function listSearchItems() {
    item_loading = true;
    let items = await client.service('athlete').find({
      query: {
        $or: [
          {
            name: {
            $like: '%'+search+'%'
          },
        }, {
          surname: {
            $like: '%'+search+'%'
          },
        },{
          email: {
            $like: '%'+search+'%'
          },
        },{
          mobile: {
            $like: '%'+search+'%'
          },
        },{
          id_number: {
            $like: '%'+search+'%'
          },
        }
        ],
        $sort: { name: 1, surname: 1 },
        $limit: search_limit,
        $skip: search_skip
      }
    });

    // console.log(items);
    search_data = items.data;
    search_total = items.total;
    search_skip = items.skip;
    item_loading = false;

    search_pages = Math.floor(search_total/search_limit);

  }

  const columns = [
  {
    key: "name",
    title: "Name",
    value: v => v.name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      let letrs = {};
      rows.forEach(row => {
        let letr = row.name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.name.charAt(0).toLowerCase()
  },
  {
    key: "surname",
    title: "Surname",
    value: v => v.surname,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      let letrs = {};
      rows.forEach(row => {
        let letr = row.surname.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.surname.charAt(0).toLowerCase()
  },
  {
    key: "gender",
    title: "Gender",
    value: v => v.gender,
    sortable: false,
  },
  {
    key: "age",
    title: "Age",
    value: v => v.age,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "",
    title: "Action",
    value: "",
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  }
];

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-3">
  <ItemMenu bind:show={show} bind:alert_message={item_message} menu_heading={item}/>
</div>

<div class="column is-9">
  <div class="box">
    {#if item_message}
      <div class="notification is-light is-{item_message_type}">{item_message}</div>
    {/if}
    {#if item_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      {#if show === 'add'}
      <div class="content">
        <h1 class="title">
          Add Athlete
        </h1>
      </div>
      <div class="field">
        <label class="label" for="challenge">Challenge</label>
        <div class="control">
          <div class="select is-fullwidth">
            <Select id="challenge" items={challenge_list} bind:selectedValue={challenge} on:select={listItems} isClearable={false} containerClasses="is-fullwidth" placeholder="Please Select Challenge"></Select>
          </div>
        </div>
      </div>
      {#if challenge}
        <div class="field is-grouped">
          <p class="control">
            {#if search_total > 0}
            <span class="tag is-primary is-large">Total : {search_total}</span>
            {/if}
          </p>
          <p class="control is-expanded">
            <input class="input" type="text" bind:value={search} placeholder="Search Athletes by Name, Surname, Email, Mobile, ID Number" on:keypress={checkEnter}>
          </p>
          <p class="control">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="button is-info" on:click={() => listSearchItems()}>
              Search
            </a>
          </p>
        </div>
        <div class="columns">
          <div class="column is-half">
          {#if search_data.length > 0}
              <SvelteTable columns={columns} rows={search_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
                <tr slot="row" let:row let:n>
                  <td>{row.name}</td>
                  <td>{row.surname}</td>
                  <td>{row.gender}</td>
                  <td style="text-align:center">{row.age}</td>
                  <td style="text-align: center;">
                    <button class="button is-small is-success" on:click={() => selectItem(row.id, row.name, row.surname, row.age, row.gender)}>
                      <span class="icon is-small">
                        <i class="fa fa-edit"></i>
                      </span>
                    </button>
                  </td>
                </tr>
              </SvelteTable>                                
              {#if search_total > 25}
                <!-- svelte-ignore a11y-no-redundant-roles -->
                <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a class="pagination-previous" on:click={() => paginate(search_page-1)}>Previous</a>
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a class="pagination-next" on:click={() => paginate(search_page+1)}>Next page</a>
                  <ul class="pagination-list">
                    {#each Array(search_pages) as _, i}
                      <!-- svelte-ignore a11y-missing-attribute -->
                      <li><a class="pagination-link {search_page === i ? 'is-current' : ''}" aria-label="Goto page {i}" on:click={() => paginate(i)}>{i+1}</a></li>
                    {/each}
                  </ul>
                </nav>
              {/if}
            {:else}
              <div class="notification is-warning">
                Please search for athlete or no athlete found with current search criteria.
              </div>
            {/if}
          </div>
          <div class="column is-half">
            {#if selected_data.length > 0}
              <SvelteTable columns={columns} rows={selected_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
                <tr slot="row" let:row let:n>
                  <td>{row.name}</td>
                  <td>{row.surname}</td>
                  <td>{row.gender}</td>
                  <td style="text-align:center">{row.age}</td>
                  <td style="text-align: center;">
                    <button class="button is-small is-danger" on:click={() => removeItem(row.id)}>
                      <span class="icon is-small">
                        <i class="fa fa-trash"></i>
                      </span>
                    </button>
                  </td>
                </tr>
              </SvelteTable>
              
              <button type="button" class="button is-success" on:click={() => updateAthletes()}>
                Add Athletes
              </button>
            {:else}
            <div class="notification is-warning">
              Please search for athlete above and then select on the left.
            </div>
            {/if}
            
          </div>
        </div>
        {/if}
      {:else}
      <div class="content">
        <h1 class="title">
          List Athletes
        </h1>
      </div>
        <div class="field">
          <label class="label" for="challenge">Challenge</label>
          <div class="control">
            <div class="select is-fullwidth">
              <Select id="challenge" items={challenge_list} bind:selectedValue={challenge} on:select={listItems} isClearable={false} containerClasses="is-fullwidth" placeholder="Please Select Challenge"></Select>
            </div>
          </div>
        </div>
        {#if challenge}
          {#if items_total > 0}
            <SvelteTable columns={columns} rows={items_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
              <tr slot="row" let:row let:n>
                <td>{row.name}</td>
                <td>{row.surname}</td>
                <td>{row.gender}</td>
                <td style="text-align:center">{row.age}</td>
                <td style="text-align: center;">
                  <button class="button is-small is-danger" on:click={() => removeItemFromDB(row.id)}>
                    <span class="icon is-small">
                      <i class="fa fa-trash"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </SvelteTable>  
          {:else}
            <div class="notification is-warning">
              No Athletes Loaded for Challenge
            </div>
          {/if}
        {/if}
      {/if}
    {/if}
  </div>
</div>

