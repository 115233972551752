import { writable } from "svelte/store";

const USER = { id: -1, level: "", name: "", surname: "" };

const { subscribe, set, update } = writable(USER);

const login = (user) => {
  set(user);
};

const reset = () => {
  set(USER);
};

export default {
  subscribe,
  login,
  reset,
};
