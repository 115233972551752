<script>
  import Dashboard from "../../components/Dashboard.svelte";
  import DashboardMenu from "../../components/Dashboard_Menu.svelte";
  import onScan from "onscan.js";
  import { onMount } from "svelte";

  onMount(async () => { if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); } });

  export let currentRoute = {currentRoute};
  export let params;
</script>
<DashboardMenu />
<Dashboard user={params.user}/>
