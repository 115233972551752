<script>
  import SideMenu from "../../../components/Side_Menu.svelte";
  import client from "../../../client";
  import { firstLetterAndLetterAfterSpaceUpper } from "../../../utils/strings"
  import { onMount } from 'svelte';
  import SvelteTable from "svelte-table";
  import Select from 'svelte-select';
  import * as xlsx from 'xlsx';
  import * as animateScroll from "svelte-scrollto";
  import onScan from "onscan.js";

  export let currentRoute;
  export let params = {params};

  onMount(async () => {
    // console.log(params);
    if (currentRoute.queryParams) {
      if (currentRoute.queryParams.search != "") {
        search = currentRoute.queryParams.search;
        listItems();
        if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
      }
    }
  });

  let show = "list";
  let alert_message;
  let alert_message_type = 'info';
  let item_loading = false;

  // List Variables / Functions
  let items_data = [];
  let items_total = 0;
  let items_limit = 15;
  let items_skip = 0;
  let items_pages = 0;
  let items_page = 0;
  let search = "";

  let minRaces = 10;
  let maxRaces = 20;

  async function listItems() {
    item_loading = true;
    let items = await client.service('athlete').find({
      query: {
        $or: [
          {
            name: {
            $like: '%'+search+'%'
          },
        }, {
          surname: {
            $like: '%'+search+'%'
          },
        },{
          email: {
            $like: '%'+search+'%'
          },
        },{
          mobile: {
            $like: '%'+search+'%'
          },
        },{
          id_number: {
            $like: '%'+search+'%'
          },
        }
        ],
        $sort: { name: 1, surname: 2 },
        $limit: items_limit,
        $skip: items_skip
      }
    });

    // console.log(items.data);
    items_data = items.data;
    items_total = items.total;
    items_skip = items.skip;
    item_loading = false;

    items_pages = Math.floor(items_total/items_limit);
  }

  const columns = [
  {
    key: "name",
    title: "Name",
    value: v => v.name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      let letrs = {};
      rows.forEach(row => {
        let letr = row.name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.name.charAt(0).toLowerCase()
  },
  {
    key: "surname",
    title: "Surname",
    value: v => v.surname,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      let letrs = {};
      rows.forEach(row => {
        let letr = row.surname.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.surname.charAt(0).toLowerCase()
  },
  {
    key: "gender",
    title: "Gender",
    value: v => v.gender,
    sortable: false,
  },
  {
    key: "age",
    title: "Age",
    value: v => v.age,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "comp",
    title: "Comp",
    value: v => v.comp,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "",
    title: "Select",
    value: "",
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  }
];

// Form Variables / Functions

let formModel = {
  index : -1,
  name : "",
  surname : "",
  id_number : "",
  race_number : "",
  age : 0,
  gender : "",
  email : "",
  mobile : "",
  dob : "",
  comp : 0,
  vitality: "-1",
  indemnity: "-1"
}

async function onSubmit() {
  alert_message = "";
  alert_message_type = "info";
  if (formModel.name.trim().length > 0 && formModel.surname.trim().length > 0 && formModel.id_number.trim().length > 0 && formModel.gender) {
    formModel.name = firstLetterAndLetterAfterSpaceUpper(formModel.name);
    formModel.surname = firstLetterAndLetterAfterSpaceUpper(formModel.surname);
    if (formModel.vitality == "-1") {
      formModel.vitality = null;
    }
    if (formModel.indemnity == "-1") {
      formModel.indemnity = null;
    }
    item_loading = true;
    if (formModel.index == -1) {
      try {
        let status = await client.service('athlete').create({
          name : formModel.name,
          surname : formModel.surname,
          id_number : formModel.id_number,
          card_number : formModel.race_number,
          age : formModel.age,
          gender : formModel.gender.value,
          email : formModel.email,
          mobile : formModel.mobile,
          dob : formModel.dob,
          comp : formModel.comp,
          vitality: formModel.vitality,
          indemnity: formModel.indemnity
        });
        await listItems();
        show = 'list';
        alert_message = "Athlete added successfully";
        alert_message_type = "success";
        resetModel();
      } catch(err) {
        alert_message = err;
        alert_message_type = "danger";
        item_loading = false;
        animateScroll.scrollToTop();
      }
    } else {
      try {
        let status = await client.service('athlete').update(formModel.index,{
          name : formModel.name,
          surname : formModel.surname,
          id_number : formModel.id_number,
          card_number : formModel.race_number,
          age : formModel.age,
          gender : formModel.gender.value,
          email : formModel.email,
          mobile : formModel.mobile,
          dob : formModel.dob,
          comp : formModel.comp,
          vitality : formModel.vitality,
          indemnity : formModel.indemnity
        });
        await listItems();
        show = 'list';
        alert_message = "Athlete updated successfully";
        alert_message_type = "success";
        resetModel();
      } catch(err) {
        alert_message = err;
        alert_message_type = "danger";
        item_loading = false;
        animateScroll.scrollToTop();
      }
    }
  } else {
    alert_message = "<div class='content'><ol type'1'><li>Name, Surname, ID / Passport, Gender must be completed</li></ol></div>";
    alert_message_type = "danger";
    animateScroll.scrollToTop();
  }
}

function resetModel() {
  formModel.index = -1;
  formModel.name = "";
  formModel.surname = "";
  formModel.id_number = "";
  formModel.race_number = "";
  formModel.age = 0;
  formModel.gender = "";
  formModel.email = "";
  formModel.mobile = "";
  formModel.dob = "";
  formModel.comp = 0;
  formModel.vitality = "-1";
  formModel.indemnity = "-1";
}

function selectItem(id, name, surname, id_number, race_number, age, gender, email, mobile, dob, comp, vitality, indemnity) {
  formModel.index = id;
  formModel.name = name;
  formModel.surname = surname;
  formModel.id_number = id_number;
  formModel.race_number = race_number;
  formModel.age = age;
  formModel.gender = {value: gender, label: gender};
  formModel.email = email;
  formModel.mobile = mobile;
  formModel.dob = (dob)?dob.substring(0,10):'';
  formModel.comp = comp;
  formModel.vitality = (vitality===null)?"-1":vitality+"";
  formModel.indemnity = (indemnity===null)?"-1":indemnity+"";
  show = 'add';
}

function cancelForm() {
  alert_message = "";
  alert_message_type = "info";
  resetModel();
  show = 'list';
}

function ageGenderDobFromID() {
  if (formModel.id_number.length == 13 && /^\d+$/.test(formModel.id_number)) {
    let gender = formModel.id_number.substring(6,10);
    if (parseInt(gender) >= 5000) {
      formModel.gender = {label:'Male', value: 'Male'};
    } else {
      formModel.gender = {label:'Female', value: 'Female'};
    }
    let date = formModel.id_number.substring(0,6);
    let date_string = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
    let dt = new Date(date_string);

    let today = new Date();
    if (dt > today) {
      formModel.dob = '19' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
    } else {
      formModel.dob = date_string;
    }

    dt = new Date(formModel.dob);
    var age = today.getFullYear() - dt.getFullYear();
    var m = today.getMonth() - dt.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dt.getDate())) {
        age--;
    }
    formModel.age = age;
  }
}

function checkEnter(e) {
  if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs
      listItems();
  }
}

function paginate(page) {
  if (page < 1)
    page = 0;
  if (page >= items_pages)  
    page = items_pages-1;
  items_skip = (page*items_limit);
  listItems();
  items_page = page;
}

async function downloadRaceCount() {
  alert_message_type = "";
  alert_message = "";
  if (minRaces < 10) {
    alert_message_type = "danger";
    alert_message = "More Races Than can't be less than 10";
  } else if (maxRaces - minRaces > 50) {
    alert_message_type = "danger";
    alert_message = "Differences between more and less can't be more than 50.";
  } else {
    let items = await client.service('results').find({
      query: {
          option : "raceCount",
          moreThan : minRaces,
          lessThan : maxRaces
      }
    });
    if (items.length > 0 ) {
        const ws = xlsx.utils.json_to_sheet(items);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Race Count");
        xlsx.writeFile(wb, "RaceCount-MoreThan"+minRaces+"-"+maxRaces+".xlsx");
    } else {
      alert_message_type = "warning";
      alert_message = "Nothing found for current values.";
    }
  }
}

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-3">
  <SideMenu bind:show={show} bind:alert_message={alert_message} menu_heading="Athletes" show_race_count=true/>
</div>

<div class="column is-9">
  <div class="box">
    {#if alert_message}
      <div class="notification is-light is-{alert_message_type}">{@html alert_message}</div>
    {/if}
    {#if item_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      {#if show === 'add'}
        <div class="content">
          <h1 class="title">
            {#if formModel.index == -1}
              Add Athlete
            {:else}
              Edit Athlete
            {/if}
          </h1>
        </div>
        <form
          on:submit|preventDefault="{onSubmit}"
        >
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">First Name</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.name} type="text" placeholder="First Name" minlength="1" required>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Surname Name</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.surname} type="text" placeholder="Surname Name" minlength="1" required>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">ID / Passport</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.id_number} type="text" placeholder="ID / Passport Number" minlength="1" on:blur={() => ageGenderDobFromID()} required>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Gender</label>
            <div class="control">
              <div class="select is-fullwidth">
                <Select items={[{label:'Male', value:'Male'}, {label:'Female', value:'Female'}]} bind:selectedValue={formModel.gender} isClearable={false} containerClasses="is-fullwidth" placeholder="Please Select Gender"></Select>
              </div>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Age</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.age} type="number" placeholder="Age" max="110" min="0" required>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Email</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.email} type="email" placeholder="Email Address">
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Mobile</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.mobile} type="text" placeholder="Mobile / Cell">
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Race Number</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.race_number} type="text" placeholder="Permanent Race Number">
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Date of Birth</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.dob} type="date" placeholder="Date of Birth">
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Complimentaries</label>
            <div class="control">
              <input class="input is-primary" bind:value={formModel.comp} type="number" placeholder="Complimentaries" max="100" min="0" required>
            </div>
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Vitality Consent</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select class="input is-primary" bind:value={formModel.vitality}>
                  {#if formModel.vitality === "-1"}
                    <option value="-1">Not Selected</option>
                  {/if}
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>  
          </div>
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Indemnity Consent</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select class="input is-primary" bind:value={formModel.indemnity}>
                  {#if formModel.indemnity === "-1"}
                    <option value="-1">Not Selected</option>
                  {/if}
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>  
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button id="btn-form-submit" class="button is-primary" type="submit">Save</button>
            </div>
            <div class="control">
              <button type="button" class="button is-primary is-light" on:click={() => cancelForm()}>Cancel</button>
            </div>
          </div>
        </form>
      {:else if show === 'raceCount'}
        <div class="field">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">More Races Than</label>
          <div class="control">
            <input class="input is-primary" bind:value={minRaces} type="number" placeholder="eg: Must have done less than 30 races" min="10" required>
          </div>
        </div>
        <div class="field">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Less Races Than</label>
          <div class="control">
            <input class="input is-primary" bind:value={maxRaces} type="number" placeholder="eg: Must have done more than 20 races" min="10" required>
          </div>
        </div>
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-primary" type="button" on:click={() => downloadRaceCount()}>Download</button>
          </div>
        </div>
      {:else}
      <div class="field is-grouped">
        <p class="control">
          {#if items_total > 0}
          <span class="tag is-primary is-large">Total : {items_total}</span>
          {/if}
        </p>
        <p class="control is-expanded">
          <input class="input" type="text" bind:value={search} placeholder="Search Athletes by Name, Surname, Email, Mobile, ID Number" on:keypress={checkEnter}>
        </p>
        <p class="control">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="button is-info" on:click={() => listItems()}>
            Search
          </a>
        </p>
      </div>
        {#if items_data.length > 0}
          <SvelteTable columns={columns} rows={items_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
            <tr slot="row" let:row let:n>
              <td>{row.name}</td>
              <td>{row.surname}</td>
              <td>{row.gender}</td>
              <td style="text-align:center">{row.age}</td>
              <td style="text-align:center">{row.comp}</td>
              <td style="text-align: center;">
                <button class="button is-small is-primary" on:click={() => selectItem(row.id, row.name, row.surname, row.id_number, row.card_number, row.age, row.gender, row.email, row.mobile, row.dob, row.comp, row.vitality, row.indemnity)}>
                  <span class="icon is-small">
                    <i class="fa fa-edit"></i>
                  </span>
                </button>
              </td>
            </tr>
          </SvelteTable>  
          {#if items_total > 25}
          <!-- svelte-ignore a11y-no-redundant-roles -->
          <nav class="pagination is-centered" role="navigation" aria-label="pagination">
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="pagination-previous" on:click={() => paginate(items_page-1)}>Previous</a>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="pagination-next" on:click={() => paginate(items_page+1)}>Next page</a>
            <ul class="pagination-list">
              {#each Array(items_pages) as _, i}
                <!-- svelte-ignore a11y-missing-attribute -->
                <li><a class="pagination-link {items_page === i ? 'is-current' : ''}" aria-label="Goto page {i}" on:click={() => paginate(i)}>{i+1}</a></li>
              {/each}
            </ul>
          </nav>
          {/if}
          {:else}
          <div class="notification is-warning">
              Please search for athlete or no athlete found with current search criteria.
            </div>
          {/if}
      {/if}
    {/if}
  </div>
</div>

