<script>
  import client from "../../../client";
  import { onMount } from 'svelte';
  import Select from 'svelte-select';
  import onScan from "onscan.js";
  
  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  $: if(show!=='index') { 
    listItems(); 
  }

  //Page Data
  let show = "index";
  let item_message;
  let item_message_type = 'info';
  let item_loading = false;
  
  // List Variables / Functions
  let event_list = [];
  let event_list_all = [];
  let event_from;
  let event_to;
  let event_result;
  let activity_from_list = [];
  let activity_to_list = [];
  let activity_to = [];
  let activity_result_list = [];
  let activity_result = [];
  let complete = false;
  let results = {success:0,errors:{count:0,failed:[]}};

  function clearAll() {
    event_from = null;
    event_to = null;
    event_result = null;
    activity_from_list = [];
    activity_to_list = [];
    activity_to = [];
    activity_result_list = [];
    activity_result = [];
    complete = false;
  }

  async function listItems() {
      item_loading = true;
      let items = await client.service('events').find({
        query: {
          $sort: { start_date: 0 },
          $limit: 25
        }
      });

      event_list = [];
      event_list_all = [];
      event_list_all = items.data;
      items.data.forEach(i => {
        event_list.push({value:i.id, label: i.name + " [" + i.venue_name+"] ["+dateString(i.start_date)+"]"})
      });
      item_loading = false;
    // }
  }

  function dateString(dt) {
    let dateOnly = dt.substring(0, 10);
    let timeOnly = dt.substring(11, 16);
    return dateOnly + " " + timeOnly;
  }

  async function listActivites(option) {
    item_loading = true;
    if (option == "from") {
      let e = event_list_all.find((event) => event_from.value === event.id)
      activity_from_list = [];
      let activities = JSON.parse(e.activities);
      activities.forEach((act,index) => {
        activity_from_list.push({label: act.name, value: act.id});
        activity_to[index] = {label: "", value: ""};
        activity_result[index] = {label: "", value: ""};
      });
    }
    if (option == "to") {
      let e = event_list_all.find((event) => event_to.value === event.id)
      activity_to_list = [];
      let activities = JSON.parse(e.activities);
      activities.forEach(act => {
        activity_to_list.push({label: act.name, value: act.id});
      });
    }
    if (option == "result") {
      let e = event_list_all.find((event) => event_result.value === event.id)
      console.log(e);
      activity_result_list = [];
      let activities = JSON.parse(e.activities);
      activities.forEach(act => {
        activity_result_list.push({label: act.name, value: act.id});
      });
      console.log(activity_result_list);
    }
    item_loading = false;
  }

  function validateToList() {
    let valid = true;
    for(let i = 0; i<activity_from_list.length;++i) {
      if (activity_to[i].value == "") {
        valid = false;
      } 
    }
    if (show == "calc") {
      for(let x = 0; x<activity_result_list.length;++x) {
        if (x < activity_result.length) {
          if (activity_result[x].value == "") {
            valid = false;
          }
        }
      }  
      if (!event_from || !event_to || !event_result) {
        valid = false;
      }
    }
    complete = valid;
  }

  async function copyAthletes() {
    item_loading = true;
    results = {success:0,errors:{count:0,failed:[]}};
    for(let i = 0; i<activity_from_list.length;++i) {
      let athletes_from = await client.service('race').find({
        query: {
          $and: [
            {event_id : event_from.value},
            {activity_id : activity_from_list[i].value}
          ],
          $limit: 500,
        }
      });
      for (let i2 = 0; i2 < athletes_from.length; i2++) {
        const element = athletes_from[i2];
        try {
          await client.service('race').create({
            event_id : event_to.value,
            activity_id : activity_to[i].value,
            athlete_id : element.athlete_id,
            race_number : element.race_number,
            gender : element.gender,
            age : element.age,
            category : element.category,
            comp : element.comp,
            pre_entry : element.pre_entry,
            paid_method : element.paid_method,
            paid_amount : element.paid_amount,
            not_paid : element.not_paid,
            dog : element.dog,
            temperature : element.temperature,
            status : "register",
            extra : "",
            start : null
          });
          results.success++
        } catch(e) {
          console.error(e);
          results.errors.count++;
          results.errors.failed.push(e.message);
        }
      } 
    }
    item_loading = false;
  }

  async function combineResults() {
    item_loading = true;
    let evt = event_list_all.find((event) => event_result.value === event.id)
    results = {success:0,errors:{count:0,failed:[]}};
    for(let i = 0; i<activity_from_list.length;++i) {
      let athletes_from = await client.service('race').find({
        query: {
          $and: [
            {event_id : event_from.value},
            {activity_id : activity_from_list[i].value}
          ],
          $limit: 500,
        }
      });
      let athletes_to = await client.service('race').find({
        query: {
          $and: [
            {event_id : event_to.value},
            {activity_id : activity_to_list[i].value}
          ],
          $limit: 500,
        }
      });

      for (let x = 0; x < athletes_from.length; x++) {
        const athleteFrom = athletes_from[x];
        let athleteTo = athletes_to.find(t => t.athlete_id === athleteFrom.athlete_id)
        if (athleteTo) {
          if (athleteTo.status == 'finished' && athleteFrom.status == 'finished') {
            let duration = addTimes(athleteFrom.duration,athleteTo.duration);
            let finishTime = addDuration(duration,new Date(evt.start_date));
            try {
              let status = await client.service('race').create({
                event_id : event_result.value,
                activity_id : activity_result[i].value,
                athlete_id : athleteTo.athlete_id,
                race_number : athleteTo.race_number,
                gender : athleteTo.gender,
                age : athleteTo.age,
                category : athleteTo.category,
                comp : athleteTo.comp,
                pre_entry : athleteTo.pre_entry,
                paid_method : athleteTo.paid_method,
                paid_amount : athleteTo.paid_amount,
                not_paid : athleteTo.not_paid,
                dog : athleteTo.dog,
                temperature : athleteTo.temperature,
                status : "register",
                extra : "",
                start : new Date(evt.start_date).toISOString().substring(0,16),
              });
              await client.service('race').patch(status.id,{
                event_id: status.event_id,
                activity_id: status.activity_id,
                gender: status.gender,
                category: status.category,
                status : "finished",
                finish : finishTime.toISOString().substring(0,16),
                duration : duration,
                extra : "stage:"+athleteFrom.activity_id+":"+athleteTo.activity_id
              });
              results.success++
            } catch(e) {
              console.error(e);
              results.errors.count++;
              results.errors.failed.push(e.message);
            }
          }
        }
      }
    }
    clearAll();
    item_loading = false;
  }

  function addTimes(startTime, endTime) {
    let times = [0, 0, 0];
    let max = times.length;

    let a = (startTime || "").split(":");
    let b = (endTime || "").split(":");

    // normalize time values
    for (let i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
    }

    // store time values
    for (let j = 0; j < max; j++) {
      times[j] = a[j] + b[j];
    }

    let hours = times[0];
    let minutes = times[1];
    let seconds = times[2];

    if (seconds >= 60) {
      let m = (seconds / 60) << 0;
      minutes += m;
      seconds -= 60 * m;
    }

    if (minutes >= 60) {
      let h = (minutes / 60) << 0;
      hours += h;
      minutes -= 60 * h;
    }

    return (
      ("0" + hours).slice(-2) +
      ":" +
      ("0" + minutes).slice(-2) +
      ":" +
      ("0" + seconds).slice(-2)
    );
  }

  function addDuration(duration,date) {
    let numOfHours, numOfMinutes, numOfSeconds = 0;
    let durationSplit = duration.split(":");
    numOfHours = durationSplit[0];
    numOfMinutes = durationSplit[1];
    numOfSeconds = durationSplit[2];
    let dateCopy = new Date(date.getTime());

    dateCopy.setTime(dateCopy.getTime() + numOfHours * 60 * 60 * 1000);
    dateCopy.setTime(dateCopy.getTime() + numOfMinutes * 60 * 1000);
    dateCopy.setTime(dateCopy.getTime() + numOfSeconds * 1000);

    return dateCopy;
  }


</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-12">
  <div class="box">
    {#if item_message}
      <div class="notification is-light is-{item_message_type}">{@html item_message}</div>
    {/if}
    {#if item_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      <div class="columns">
        <div class="column is-one-quarter"></div>
        <div class="column is-one-quarter">
          <button class="button is-primary{show=='copy'?'':' is-outlined'} is-fullwidth" on:click={() => show="copy"}>
            Copy Athletes
          </button>
        </div>  
        <div class="column is-one-quarter">
          <button class="button is-primary{show=='calc'?'':' is-outlined'} is-fullwidth" on:click={() => show="calc"}>
            Calculate Results
          </button>
        </div>
        <div class="column is-one-quarter"></div>
      </div>
      {#if show == "copy"}
        <div class="columns">
          <div class="column is-half">
            <h3>From</h3>
            <Select items={event_list} bind:selectedValue={event_from} on:select={() => listActivites('from')} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event From"></Select>
          </div>
          <div class="column is-half">
            <h3>To</h3>
            <Select items={event_list} bind:selectedValue={event_to} on:select={() => listActivites('to')} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event From"></Select>
          </div>
        </div>
        {#if activity_from_list.length > 0}
          {#each activity_from_list as actf,index}
          <div class="columns box m-1 p-1">
            <div class="column is-one-quarter"></div>
            <div class="column is-one-quarter">
              <b>{actf.label}</b>
            </div>
            <div class="column is-one-quarter">
              <Select items={activity_to_list} bind:selectedValue={activity_to[index]} on:select={() => validateToList()} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Activity To"></Select>
            </div>
            <div class="column is-one-quarter">
            </div>
          </div>    
          {/each}
        {/if}
      {/if}
      {#if show == "calc"}
        <div class="columns">
          <div class="column is-one-third">
            <h3>Event 1</h3>
            <Select items={event_list} bind:selectedValue={event_from} on:select={() => listActivites('from')} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event From"></Select>
          </div>
          <div class="column is-one-third">
            <h3>Event 2</h3>
            <Select items={event_list} bind:selectedValue={event_to} on:select={() => listActivites('to')} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event From"></Select>
          </div>
          <div class="column is-one-third">
            <h3>Results</h3>
            <Select items={event_list} bind:selectedValue={event_result} on:select={() => listActivites('result')} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Event Result"></Select>
          </div>
        </div>
        {#if activity_from_list.length > 0}
          {#each activity_from_list as actf,index}
          <div class="columns box m-1 p-1">
            <div class="column is-one-third">
              <b>{actf.label}</b>
            </div>
            <div class="column is-one-third">
              <Select items={activity_to_list} bind:selectedValue={activity_to[index]} on:select={() => validateToList()} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Activity To"></Select>
            </div>
            <div class="column is-one-third">
              <Select items={activity_result_list} bind:selectedValue={activity_result[index]} on:select={() => validateToList()} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Activity To"></Select>
            </div>
          </div>    
          {/each}
        {/if}
      {/if}
    {/if}
  </div>
  {#if complete && !item_loading}
  <div class="box text-center">
    {#if show === 'copy'}
     <button class="button is-primary" on:click={() => copyAthletes()}>Copy Athletes</button>
    {/if} 
    {#if show === 'calc'}
     <button class="button is-primary" on:click={() => combineResults()}>Calculate Results</button>
    {/if} 
    <button class="button is-warning" on:click={() => clearAll()}>Clear</button>
  </div>
  {/if}

  {#if results.success > 0 || results.errors.count > 0}
    <div class="notification is-success">
      Successfully Copied Over [{results.success}]
    </div>
    <div class="notification is-danger">
      Errors [{results.errors.count}]
      <table class="table mt-1">
        <tbody>
          {#each results.errors.failed as item}
             <tr><td>{item}</td></tr>
          {/each}
        </tbody>
      </table>
    </div>
  {/if}
</div>

