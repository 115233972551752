<script>
  import client from "../../../client";
  import { firstLetterAndLetterAfterSpaceUpper, validateEmail } from "../../../utils/strings";
  import { category } from "../../../utils/race";
  import { convertDateTimeToTime } from "../../../utils/dates";
  import { validateScanCode } from "../../../utils/strings";
  import { navigateTo, Navigate } from "svelte-router-spa";
  import { onMount } from 'svelte';
  import Clock from "../../../components/Clock.svelte";
  import SvelteTable from "svelte-table";
  import * as animateScroll from "svelte-scrollto";
  import onScan from "onscan.js";
  
  export let currentRoute;
  export let params = {params};

  //Register to listen for new registered athletes
  client.service('race').on("created", async (race) => {
    // console.log("Race Event : ",race);
    // console.log("Current Event : ",raceEvent);
    if (race.event_id === raceEvent.id) {
      registeredAthletes();
    }
  });
  client.service('race').on("updated", async (race) => {
    if (race.event_id === raceEvent.id) {
      registeredAthletes();
    }
  });
  client.service('race').on("patched", async (race) => {
    if (race.event_id === raceEvent.id) {
      registeredAthletes();
    }
  });
  client.service('events').on("patched", async (raceEventTmp) => {
    if (raceEventTmp.id === raceEvent.id) {
      raceEvent = raceEventTmp;
      activities = JSON.parse(raceEvent.activities);
    }
  });

  onMount(async () => {
    if (currentRoute.namedParams.id) {
      try {
        raceEvent = await client.service('events').get(parseInt(currentRoute.namedParams.id));
        if (!raceEvent) {
          navigateTo('/');
        } else {
          /* Remove Scanning */
          if (onScan.isAttachedTo(document)) { 
            onScan.detachFrom(document); 
          }

          // Enable scan events for the entire document
          // if (!onScan.isAttachedTo(document)) {
            onScan.attachTo(document, {
              reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
              onScan: async function(sScancode) { // Alternative to document.addEventListener('scan')
                  if (validateScanCode(sScancode)) {
                    formModel.race_number = sScancode;
                    if (getAthleteFromRaceNumber()) {
                      let item = await client.service('race').find({
                        query: {
                          event_id : raceEvent.id,
                          athlete_id : formModel.index,
                          $limit: 1,
                        }
                      });
                      if (item.total > 0) {
                        selectAthlete(item.data[0],'reselect');
                      }
                    }
                  } else {
                    audio.play();
                  }
              },
              scanError: function(error) {
                console.error(error);
              },
              onPaste: function(epaste, oEvent) {
                if (oEvent.path[0].id == "scanCode" && oEvent.type == "paste") {
                  formModel.race_number = epaste;
                  searchAthletes('race_number');
                }
              }
            });
          // } else {
          //   console.log("Already Attached");
          // }

          activities = JSON.parse(raceEvent.activities);
          activities.forEach(act => {
            activitiesSelect.push({label: act.name+" [R"+act.price+"]", value: act.id, amount: act.price});
          });
          activitiesSelect = activitiesSelect;
          registeredAthletes();
        }
      } catch (err) {
        console.error(err);
        navigateTo('/');
      }
    }
  });

  // Page Variables
  let alert_message;
  let alert_message_type = 'info';
  let alert_message_start;
  let alert_message_start_special;
  let athlete_loading = false;
  let register_loading = false;
  let raceEvent = { name : ""};
  let activities = [];
  let activitiesSelect = [];
  let showList = 'registered';
  let showStartModal = false;
  let showSpecialStartModal = false;
  let selectedStartActivites = [];
  let start_loading = false;

  // Registered Athletes
  let items_data = [];
  let items_total = 0;
  // let items_limit = 0;
  // let items_skip = 0;
  // let items_pages = 0;
  // let items_page = 0;
  let items_started = 0;
  let items_registered = 0;

  // Search Athletes
  let search = "";
  let search_data = [];
  // let search_total = 0;
  // let search_limit = 15;
  // let search_skip = 0;
  // let search_pages = 0;
  // let search_page = 0;

  // Special Start
  let special_start = [];

  async function searchAthletes(searchby) {
    try {
      athlete_loading = true;
      let query = "";
      if (searchby === 'surname') {
        if (formModel.surname.length > 2) {
          query = { 
              surname : { 
                $like: '%'+formModel.surname+'%'
              }
          };
        }
      } else if (searchby === 'id_number') {
        if (formModel.id_number.length > 4) {
          query = { 
              id_number : { 
                $like: '%'+formModel.id_number+'%'
              }
          };
        }
      } else if (searchby === 'race_number') {
        if (formModel.race_number.length > 4) {
          query = { 
              race_number : { 
                $like: formModel.race_number
              }
          };
        }
      }

      if (query != "") {
        let items = await client.service('athlete').find({
          query: {
            query,
            $sort: { name: 1 },
            $limit: 200,
            $skip: 0
          }
        });

        // console.log(items);
        search_data = items.data;
        // search_total = items.total;
        // search_skip = items.skip;
        // search_pages = Math.floor(search_total/search_limit);
        athlete_loading = false;
        showList = 'search';
      } else {
        athlete_loading = false;
      }
    } catch(err) {
      alert_message = err;
      alert_message_type = "danger";
      athlete_loading = false;
      animateScroll.scrollToTop();
      error = true;
    }
  }

  async function registeredAthletes() {
    athlete_loading = true;

    let items = await client.service('race').find({
      query: {
        event_id : raceEvent.id,
        $sort: { id: 0 },
      }
    });

    // console.log(items);
    items_data = items;
    items_total = items.length;
    // items_skip = items.skip;
    // items_pages = Math.floor(items_total/items_limit);
    items_data = items_data;
    items_started = items_data.filter(x => x.start!=null).length;
    items_registered = items_data.filter(x => x.status!='pre-entry').length;
    athlete_loading = false;
  }

  //Filter Registered Athletes on name, surname and race_number
  $: filteredList = items_data.filter(item => (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.surname.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.race_number.toLowerCase().indexOf(search.toLowerCase()) !== -1));

  //Form Validation
  $: formValidate = validateFormModel(formModel);

  const columns = [
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "surname",
      title: "Surname",
      value: v => v.surname,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "activity_name",
      title: "Activity",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "race_number",
      title: "Race Number",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "gender",
      title: "Gender",
      value: v => v.gender,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "category",
      title: "Category",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "paid_amount",
      title: "Paid",
      value: v => v.paid_amount,
      sortable: false,
      headerClass: "text-center",
    },
    {
      key: "start",
      title: "Start",
      value: v => v.start,
      sortable: false,
      headerClass: "text-center",
    },
    {
      key: "special_start",
      title: "o",
      value: v => v.special_start,
      sortable: false,
      headerClass: "text-center",
    }
  ];

  const columns_search = [
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: true,
      headerClass: "text-left",
      class: "text-left",
      filterOptions: rows => {
        let letrs = {};
        rows.forEach(row => {
          let letr = row.name.charAt(0);
          if (letrs[letr] === undefined)
            letrs[letr] = {
              name: `${letr.toUpperCase()}`,
              value: letr.toLowerCase()
            };
        });
        // fix order
        letrs = Object.entries(letrs)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {});
        return Object.values(letrs);
      },
      filterValue: v => v.name.charAt(0).toLowerCase()
    },
    {
      key: "surname",
      title: "Surname",
      value: v => v.surname,
      sortable: true,
      headerClass: "text-left",
      class: "text-left",
      filterOptions: rows => {
        let letrs = {};
        rows.forEach(row => {
          let letr = row.surname.charAt(0);
          if (letrs[letr] === undefined)
            letrs[letr] = {
              name: `${letr.toUpperCase()}`,
              value: letr.toLowerCase()
            };
        });
        // fix order
        letrs = Object.entries(letrs)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {});
        return Object.values(letrs);
      },
      filterValue: v => v.surname.charAt(0).toLowerCase()
    },
    {
      key: "gender",
      title: "Gender",
      value: v => v.gender,
      sortable: false,
      headerClass: "text-center",
      class: "text-center"
    },
    {
      key: "age",
      title: "Age",
      value: v => v.age,
      sortable: false,
      headerClass: "text-center",
      class: "text-center"
    },
    {
      key: "id_number",
      title: "ID / Passport",
      value: v => v.id_number,
      sortable: false,
      headerClass: "text-center",
      class: "text-center"
    },
    {
      key: "",
      title: "Select",
      value: "",
      sortable: false,
      headerClass: "text-center",
      class: "text-center"
    }
  ];

  // Form Variables / Functions

  let formModel = {
    index : -1,
    name : "",
    surname : "",
    id_number : "",
    race_number : "",
    age : 0,
    gender : "",
    email : "",
    mobile : "",
    dob : "",
    activity : "",
    comp : "0",
    payment : "",
    paid_amount : 0,
    temperature : 0,
    dog : 0,
    race_index : -1,
    status : "",
    not_paid : false,
    total: 0,
    total_comp: 0,
    vitality: "",
    indemnity: "",
  }

  async function onSubmit() {
    // console.log(formModel);
    alert_message = "";
    alert_message_type = "info";
    if (validateForm()) {
      formModel.name = firstLetterAndLetterAfterSpaceUpper(formModel.name);
      formModel.surname = firstLetterAndLetterAfterSpaceUpper(formModel.surname);
      if (formModel.vitality == "-1") {
        formModel.vitality = null;
      }
      if (formModel.indemnity == "-1") {
        formModel.indemnity = null;
      }
      register_loading = true;
      let error = false;
      if (formModel.index == -1) {
        try {
          let status = await client.service('athlete').create({
            name : formModel.name,
            surname : formModel.surname,
            id_number : formModel.id_number,
            age : formModel.age,
            gender : (formModel.gender.value?formModel.gender.value:formModel.gender),
            email : formModel.email,
            mobile : formModel.mobile,
            dob : (formModel.dob === "")?null:formModel.dob,
            vitality : formModel.vitality,
            indemnity : formModel.indemnity
          });
          formModel.index = status.id;
        } catch(err) {
          console.error(err);
          alert_message = err;
          alert_message_type = "danger";
          register_loading = false;
          animateScroll.scrollToTop();
          error = true;
        }
      } else {
        try {
          let complimentaries = 0;
          if (parseInt(formModel.comp) > 0) {
            if (formModel.total_comp > 0) {
              complimentaries = formModel.total_comp-1;
            }
          }
          let status = await client.service('athlete').patch(formModel.index,{
            name : formModel.name,
            surname : formModel.surname,
            id_number : formModel.id_number,
            age : formModel.age,
            gender : (formModel.gender.value?formModel.gender.value:formModel.gender),
            email : formModel.email,
            mobile : formModel.mobile,
            comp: complimentaries,
            vitality: formModel.vitality,
            indemnity: formModel.indemnity
          });
        } catch(err) {
          console.error(err);
          alert_message = err;
          alert_message_type = "danger";
          register_loading = false;
          animateScroll.scrollToTop();
          error = true;
        }
      }

      if (!error) {
        // console.log(formModel);
        if (formModel.race_index == -1) {
          try {
            let start_time = null;
            /* Removed So if group start done single starts can happen afterwards.*/
            // for (let index2 = 0; index2 < activities.length; index2++) {
            //   let act = activities[index2];
            //   if (formModel.activity.value == act.id) {
            //     if (act.start) {
            //       start_time = act.start;
            //     }
            //   }
            // }
            let paid_amount = formModel.activity.amount;
            if (formModel.paid_amount > 0) { paid_amount = formModel.paid_amount; }
            else if (formModel.age > 59 || formModel.age < 14) { paid_amount = 30; }
            let status = await client.service('race').create({
              event_id : raceEvent.id,
              activity_id : formModel.activity.value,
              athlete_id : formModel.index,
              race_number : formModel.race_number,
              gender : (formModel.gender.value?formModel.gender.value:formModel.gender),
              age : formModel.age,
              category : category(formModel.age),
              comp : parseInt(formModel.comp),
              pre_entry : formModel.pre_entry,
              paid_method : formModel.payment,
              paid_amount : paid_amount,
              not_paid : false,
              dog : formModel.dog,
              temperature : formModel.temperature,
              status : "register",
              extra : "",
              start : start_time
            });
            alert_message = "Athlete registered successfully";
            alert_message_type = "success";
            resetModel();
            register_loading = false;
          } catch(err) {
            console.error(err);
            if (err.message.includes("race_event_id_athlete_id_unique")) {
              alert_message = "Athlete Already Registered for current Race!";
            } else if (err.message.includes("race_event_id_race_number_unique")) {
              alert_message = "Race Number Already In Use : "+formModel.race_number;
            } else {
              alert_message = err;
            }
            alert_message_type = "danger";
            register_loading = false;
            animateScroll.scrollToTop();
            error = true;
          }
        } else {
          try {
            let paid_amount = formModel.activity.amount;
            if (formModel.paid_amount > 0) { paid_amount = formModel.paid_amount; }
            else if (formModel.age > 59 || formModel.age < 14) { paid_amount = 30; }
            let status = await client.service('race').patch(formModel.race_index,{
              event_id : raceEvent.id,
              activity_id : formModel.activity.value,
              athlete_id : formModel.index,
              race_number : formModel.race_number,
              gender : (formModel.gender.value?formModel.gender.value:formModel.gender),
              age : formModel.age,
              category : category(formModel.age),
              comp : parseInt(formModel.comp),
              pre_entry : formModel.pre_entry,
              paid_method : formModel.payment,
              paid_amount : paid_amount,
              not_paid : false,
              dog : formModel.dog,
              temperature : formModel.temperature,
              status : "register",
              extra : ""
            });
            alert_message = "Athlete registered successfully";
            alert_message_type = "success";
            resetModel();
            register_loading = false;
          } catch(err) {
            console.error(err);
            if (err.message.includes("race_event_id_athlete_id_unique")) {
              alert_message = "Athlete Already Registered for current Race!";
            } else if (err.message.includes("race_event_id_race_number_unique")) {
              alert_message = "Race Number Already In Use : "+formModel.race_number;
            } else {
              alert_message = err;
            }
            alert_message = err;
            alert_message_type = "danger";
            register_loading = false;
            animateScroll.scrollToTop();
            error = true;
          }
        }
      }
    } else {
      animateScroll.scrollToTop();
    }
  }

  function validateForm() {
    if (formModel.name.trim().length > 0 && 
      formModel.surname.trim().length > 0 && 
      formModel.id_number.trim().length > 0 && 
      formModel.race_number.trim().length > 0 && 
      // formModel.temperature > 0 && 
      formModel.gender &&
      formModel.age &&
      formModel.payment &&
      formModel.activity) {

      if (formModel.comp == "0" && formModel.payment == "none") {
        alert_message = "<div class='content'><ol type'1'><li>Payment Method can't be None if not a Complimentary</li></ol></div>";
        alert_message_type = "danger";
        return false;    
      }  

      if (formModel.email) {
        if (!validateEmail(formModel.email)) {
          alert_message = "<div class='content'><ol type'1'><li>Please enter a valid email address.</li></ol></div>";
          alert_message_type = "danger";
          return false;    
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      alert_message = "<div class='content'><ol type'1'><li>Name, Surname, ID / Passport, Gender, Race Number, Age, Payment, Activity must be completed</li></ol></div>";
      alert_message_type = "danger";
      return false;    
    }
  }

  function validateFormModel(fm) {
    // console.log(fm);
    let validations = {name:true, surname: true, id_number: true, race_number: true, gender: true, age: true, payment: true, activity: true, email: true};

    if (fm.name)
      if (fm.name.trim().length > 0) 
        validations.name = false; 

    if (fm.surname)  
      if (fm.surname.trim().length > 0)
        validations.surname = false;

    if (fm.id_number)
      if (fm.id_number.trim().length > 0)
        validations.id_number = false;

    if (fm.race_number)
      if (fm.race_number.trim().length > 0)
        validations.race_number = false;

    if (fm.gender)
      validations.gender = false;

    if (fm.age)
      validations.age = false;

    if (fm.payment) {
      if (fm.comp == "0" && fm.payment == "none") {
        validations.payment = true;
      } else {
        validations.payment = false;
      }
    }

    if (fm.activity)
      validations.activity = false;

    if (fm.email) {
      validations.email = validateEmail(fm.email);
    }

    // if (fm.temperature)
    //   if (fm.temperature > 0)
    //     validations.temperature = false;
    // console.log(validations);

    return validations;
  }

  function resetModel() {
    formModel.index = -1;
    formModel.name = "";
    formModel.surname = "";
    formModel.id_number = "";
    formModel.race_number = "";
    formModel.age = 0;
    formModel.gender = "";
    formModel.email = "";
    formModel.mobile = "";
    formModel.dob = "";
    formModel.activity = "";
    formModel.comp = "0";
    formModel.payment = "";
    formModel.paid_amount = 0;
    formModel.temperature = 0;
    formModel.dog = "0";
    formModel.status = "";
    formModel.race_index = -1;
    formModel.not_paid = false;
    formModel.total = 0;
    formModel.total_comp = 0;
    formModel.vitality = "";
    formModel.indemnity = "";
  }

  async function selectAthlete(item, option) {
    register_loading = true;
    // console.log(item);
    // console.log(option);
    
    if (option == 'reselect') {
      let athlete = await client.service('athlete').get(parseInt(item.athlete_id));
      let total_races = await client.service('race').find({
        query: {
          $select: [ 'race.id' ],
          athlete_id: parseInt(item.athlete_id)
        }
      });
      formModel.total_comp = athlete.comp;
      formModel.total = total_races.length;
      // console.log(athlete);
      formModel.race_index = item.id;
      item.id = athlete.id;
      item.id_number = athlete.id_number;
      item.email = athlete.email;
      item.mobile = athlete.mobile;
      formModel.comp = item.comp+"";
      formModel.payment = item.paid_method;
      formModel.not_paid = item.not_paid;
      formModel.paid_amount = item.paid_amount;
      formModel.temperature = item.temperature;
      formModel.vitality = (athlete.vitality===null)?"-1":athlete.vitality+"";
      formModel.indemnity = (athlete.indemnity===null)?"-1":athlete.indemnity+"";
      if (item.dog)
        formModel.dog = "1";
      else
        formModel.dog = "0";
      activitiesSelect.forEach(act => {
        if (act.value == item.activity_id) {
          formModel.activity = act;
        }
      });
    } else if (option === 'select') {
      let athlete = await client.service('athlete').get(parseInt(item.id));
      let total_races = await client.service('race').find({
        query: {
          $select: [ 'race.id' ],
          athlete_id: parseInt(item.id)
        }
      });
      formModel.total = total_races.length;
      formModel.total_comp = athlete.comp;
      formModel.vitality = (athlete.vitality===null)?"-1":athlete.vitality+"";
      formModel.indemnity = (athlete.indemnity===null)?"-1":athlete.indemnity+"";
    }
    formModel.index = item.id;
    formModel.name = item.name;
    formModel.surname = item.surname;
    formModel.id_number = item.id_number;
    if (item.pre_entry && item.race_number.includes("PRE-")) {
      formModel.race_number = "";
    } else {
      if (item.race_number) {
        formModel.race_number = item.race_number;
      } else if (item.card_number) {
        formModel.race_number = item.card_number;
      }
    }
    formModel.age = item.age;
    formModel.gender = item.gender;
    formModel.email = item.email;
    formModel.mobile = item.mobile;
    
    showList = 'registered';
    validateFormModel(formModel);
    register_loading = false;
  }

  function cancelForm() {
    alert_message = "";
    alert_message_type = "info";
    resetModel();
    showList = 'registered';
  }

  async function ageGenderDobFromIDAndGetAthlete() {
    let athlete = await client.service('athlete').find({
        query: {
          id_number: formModel.id_number,
          $limit: 1,
        }
      });

    // console.log(athlete);  
    if (athlete.total > 0)   {
      formModel.index = athlete.data[0].id;
      formModel.name = athlete.data[0].name;
      formModel.surname = athlete.data[0].surname;
      formModel.id_number = athlete.data[0].id_number;
      formModel.age = athlete.data[0].age;
      formModel.gender = athlete.data[0].gender;
      formModel.email = athlete.data[0].email;
      formModel.mobile = athlete.data[0].mobile;
      formModel.vitality = (athlete.data[0].vitality===null)?"-1":athlete.data[0].vitality+"";
      formModel.indemnity = (athlete.data[0].indemnity===null)?"-1":athlete.data[0].indemnity+"";
    }
    if (formModel.id_number.length == 13 && /^\d+$/.test(formModel.id_number)) {
      let gender = formModel.id_number.substring(6,10);
      if (parseInt(gender) >= 5000) {
        formModel.gender = 'Male';
      } else {
        formModel.gender = 'Female';
      }
      let date = formModel.id_number.substring(0,6);
      let date_string = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
      let dt = new Date(date_string);

      let today = new Date();
      if (dt > today) {
        formModel.dob = '19' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
      } else {
        formModel.dob = date_string;
      }

      dt = new Date(formModel.dob);
      var age = today.getFullYear() - dt.getFullYear();
      var m = today.getMonth() - dt.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < dt.getDate())) {
          age--;
      }
      formModel.age = age;
    }
  }

  async function getAthleteFromRaceNumber() {
    let athlete = await client.service('athlete').find({
        query: {
          card_number: formModel.race_number,
          $limit: 1,
        }
      });

    // console.log(athlete);  
    if (athlete.total > 0)   {
      formModel.index = athlete.data[0].id;
      formModel.name = athlete.data[0].name;
      formModel.surname = athlete.data[0].surname;
      formModel.id_number = athlete.data[0].id_number;
      formModel.age = athlete.data[0].age;
      formModel.gender = athlete.data[0].gender;
      formModel.email = athlete.data[0].email;
      formModel.mobile = athlete.data[0].mobile;
      formModel.vitality = (athlete.data[0].vitality===null)?"-1":athlete.data[0].vitality+"";
      formModel.indemnity = (athlete.data[0].indemnity===null)?"-1":athlete.data[0].indemnity+"";
    
      if (formModel.id_number.length == 13 && /^\d+$/.test(formModel.id_number)) {
        let gender = formModel.id_number.substring(6,10);
        if (parseInt(gender) >= 5000) {
          formModel.gender = 'Male';
        } else {
          formModel.gender = 'Female';
        }
        let date = formModel.id_number.substring(0,6);
        let date_string = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
        let dt = new Date(date_string);

        let today = new Date();
        if (dt > today) {
          formModel.dob = '19' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
        } else {
          formModel.dob = date_string;
        }

        dt = new Date(formModel.dob);
        var age = today.getFullYear() - dt.getFullYear();
        var m = today.getMonth() - dt.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dt.getDate())) {
            age--;
        }
        formModel.age = age;
      }

      return true;
    } else {
      return false;
    }
  }

  function checkComp() {
    if (formModel.comp == '0') {
      formModel.payment = ""
    } else {
      formModel.payment = "none"
    }
  }

  function checkEnterIdSearch(event) {
    if(event.keyCode === 13){
        event.preventDefault(); // Ensure it is only this code that runs
        searchAthletes('id_number');
    }
  }

  function checkEnterSurnameSearch(event) {
    if(event.keyCode === 13){
        event.preventDefault(); // Ensure it is only this code that runs
        searchAthletes('surname');
    }
  }

  function selectStartActivity(e,id) {
    alert_message_start = null;
    selectedStartActivites = selectedStartActivites.filter(function(value, index, arr){
        return value != id;
    });
    if (e.target.checked) {
      selectedStartActivites.push(id);
    }
  }

  async function startActivities() {
    if (selectedStartActivites.length > 0) {
      try {
        alert_message_start = "";
        // let start_time = convertDateTimeHTML5(new Date(),2);
        let servertime = await client.service("email/time").find();
        let start_time = servertime.datetime;
        for (let index = 0; index < selectedStartActivites.length; index++) {
          // service.patch(null, {number: 456}, { query: { text: 'Test 1' }});
          let status = await client.service('race').patch(null,
          {
            start : start_time
          },
          {
            query : {
                event_id : raceEvent.id,
                activity_id : selectedStartActivites[index],
                special_start : false,
                status: {
                  $nin: [ 'pre-entry', 'finished' ]
                }
            }
          });
          for (let index2 = 0; index2 < activities.length; index2++) {
            let act = activities[index2];
            if (selectedStartActivites[index] == act.id) {
              act.start = start_time;
            }
          }
          activities = activities;
          status = await client.service('events').patch(raceEvent.id,
          {
            activities : JSON.stringify(activities)
          });
        }
        activities = activities;
        raceEvent = raceEvent;
        selectedStartActivites = [];
        showStartModal = false;
        let checkboxes = document.getElementsByName('cb_start_act');
        for(var i=0, n=checkboxes.length;i<n;i++) {
          checkboxes[i].checked = false;
        }
      } catch(err) {
        console.error(err);
        alert_message_start = err;
      }
    } else {
      alert_message_start = "Please select atleast one activity!";
    }
  }

  async function startGroup() {
    if (special_start.length > 0) {
      try {
        alert_message_start_special = "";
        // let start_time = convertDateTimeHTML5(new Date(),2);
        let servertime = await client.service("email/time").find();
        let start_time = servertime.datetime;
        for (let index = 0; index < special_start.length; index++) {
          // service.patch(null, {number: 456}, { query: { text: 'Test 1' }});

          let status = await client.service('race').patch(special_start[index].id,
          {
            start : start_time,
            special_start : true
          });
          for (let index2 = 0; index2 < activities.length; index2++) {
            let act = activities[index2];
            if (selectedStartActivites[index] == act.id) {
              act.start = start_time;
            }
          }
          activities = activities;
          status = await client.service('events').patch(raceEvent.id,
          {
            activities : JSON.stringify(activities)
          });
        }
        special_start = [];
        showSpecialStartModal = false;
      } catch(err) {
        console.error(err);
        alert_message_start_special = err;
      }
    } else {
      alert_message_start_special = "Please select atleast one activity!";
    }
  }

  // function paginate(page) {
  //   if (page < 1)
  //     page = 0;
  //   if (page >= items_pages)
  //     page = items_pages-1;
  //   items_skip = (page*items_limit);
  //   listItems();
  //   items_page = page;
  // }

  function selectForSpecialStart(athlete) {
    special_start.push(athlete);
    special_start = special_start;
    filteredList = filteredList;
  }

  function removeFromSpecialStart(athlete) {
    special_start = special_start.filter(function(el) { return el.id != athlete.id; })
    filteredList = filteredList;
  }

  function checkSelected(row) {
    let found = special_start.find( ({ id }) => id === row.id );
    // console.log(row);
    if (row.start || row.status === "pre-entry" || row.status === "finished")
      return 2;

    if (found) {
      return 1;
    } else {
      return 0;
    }
  }

//Detect Scan

// Enable scan events for the entire document
// if (!onScan.isAttachedTo(document)) {
//   onScan.attachTo(document, {
//     reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
//     onScan: function(sScancode, oEvent) { // Alternative to document.addEventListener('scan')
//       console.log(oEvent);
//       if (sScancode.type === "scan") {
//         if (validateScanCode(sScancode.detail.scanCode)) {
//           // TODO:[Save Scan]
//           formModel.race_number = sScancode.detail.scanCode;
//         } 
//       }
//     },
//     // onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
//     //     console.log('Pressed: ' + iKeyCode);
//     // },
//     scanError: function(error) {
//       console.error(error);
//     },
//     onPaste: function(epaste, oEvent) {
//       console.log(oEvent);
//       if (oEvent.path[0].id == "scanCode" && oEvent.type == "paste") {
//         scanCode = epaste;
//         captureScan();
//       }
//     }
// });
// }
//Detect Scan

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }
  div :global(.text-left) {
    text-align: left;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }

  .small-cell {
    font-size: 0.75rem;
    padding: 2px;
    cursor: pointer;
  }

  .small-cell-nopointer {
    font-size: 0.75rem;
    padding: 2px;
  }
</style>
<div class="columns is-multiline">
  <div class="column is-full">
    <div class="box">
      <div class="columns">
        <div class="column is-8">
          <span class="tag is-dark is-large">{raceEvent.name}</span>
        </div>
        <div class="column is-2">
        <button type="button" class="button is-small is-success" on:click={() => showStartModal = true}>Start</button>
        <button type="button" class="button is-small is-info" on:click={() => showSpecialStartModal = true}>Group</button>
        <Navigate
                      to="admin/race/{raceEvent.id}"
                      styles="button is-small is-primary"
                    >Race</Navigate>
        </div>
        <div class="column is-2">
          <Clock />
        </div>
      </div>
    </div>
  </div>
  <div class="column is-3">
    <div class="box" class:has-background-danger={formModel.not_paid}>
    <form
            on:submit|preventDefault="{onSubmit}"
          >
          <h6 class="title is-6">Register Athlete</h6>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">ID / Passport</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
              <div class="control is-expanded">
                <!-- svelte-ignore missing-declaration -->
                <input class="input is-primary is-small" class:is-danger="{formValidate.id_number}" bind:value={formModel.id_number} type="text" placeholder="ID / Passport" minlength="1" required on:blur={() => ageGenderDobFromIDAndGetAthlete()} on:keypress={checkEnterIdSearch}>
              </div>
              <div class="control">
                <button type="button" class="button is-small" on:click={() => searchAthletes('id_number')}>
                  <span class="icon is-small">
                    <i class="fa fa-search"></i>
                  </span>
                </button>
              </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input is-primary is-small" class:is-danger="{formValidate.name}" bind:value={formModel.name} type="text" placeholder="First Name" minlength="1" required>
                </div>
                <div class="control">
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a class="button is-info is-static is-small">
                    {formModel.total}
                  </a>
                </div>
              </div>  
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Surname</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
              <div class="control is-expanded">
                <!-- svelte-ignore missing-declaration -->
                <input class="input is-primary is-small" class:is-danger="{formValidate.surname}" bind:value={formModel.surname} type="text" placeholder="Surname Name" minlength="1" required on:keypress={checkEnterSurnameSearch}>
              </div>
              <div class="control">
                <button type="button" class="button is-small" on:click={() => searchAthletes('surname')}>
                  <span class="icon is-small">
                    <i class="fa fa-search"></i>
                  </span>
                </button>
              </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Race Num</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input id="form_reg_racenumber" name="form_reg_racenumber" class="input is-primary is-small" class:is-danger="{formValidate.race_number}" bind:value={formModel.race_number} type="text" placeholder="Race Number" minlength="1" required>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Activity</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" class:is-danger="{formValidate.activity}" bind:value={formModel.activity} required>
                  <option value="" disabled selected>Select Activity</option>
                  {#each activitiesSelect as act}
                     <option value={act}>{act.label}</option>
                  {/each}
                </select>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Age</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="input is-primary is-small" class:is-danger="{formValidate.age}" bind:value={formModel.age} type="number" placeholder="Age" max="110" min="0" required>
              </div>
            </div>
          </div>  
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Gender</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" class:is-danger="{formValidate.gender}" bind:value={formModel.gender} required>
                  <option value="" disabled selected>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Email</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="input is-primary is-small" bind:value={formModel.email} type="email" placeholder="Email Address">
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Mobile</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="input is-primary is-small" bind:value={formModel.mobile} type="text" placeholder="Mobile / Cell">
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Complimentary</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <div class="control">
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a class="button is-info is-static is-small">
                    {formModel.total_comp}
                  </a>
                </div>
                <div class="control is-expanded">
                <!-- svelte-ignore a11y-no-onchange -->
                <select class="input is-primary is-small" bind:value={formModel.comp} required on:change="{() => checkComp()}">
                  <option value="0">No</option>
                  <option value="1">Previous Race</option>
                  <option value="2">Team Member</option>
                  <option value="3">Other</option>
                  <option value="4">Membership</option>
                  <option value="5">IAC Member</option>
                </select>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Payment</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" class:is-danger="{formValidate.payment}" bind:value={formModel.payment} required>
                  <option value="" selected="selected">Select Payment Method</option>
                  <option value="cash">Cash</option>
                  <option value="card">Card</option>
                  <option value="eft">EFT</option>
                  <option value="online">Online</option>
                  <option value="none">None</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
            <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Vitality</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" bind:value={formModel.vitality}>
                  <option value="" disabled>Select Vitality Consent</option>
                  {#if formModel.vitality === "-1"}
                    <option value="-1">Not Selected</option>
                  {/if}
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>  
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-small">
            <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Indemnity</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" bind:value={formModel.indemnity}>
                  <option value="" disabled>Select Indemnity Consent</option>
                  {#if formModel.indemnity === "-1"}
                    <option value="-1">Not Selected</option>
                  {/if}
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>  
          </div>
          <!-- <div class="field is-horizontal">
            <div class="field-label is-small">
              <label class="label">Temperature</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="input is-primary is-small" bind:value={formModel.temperature} class:is-danger="{formValidate.temperature}" type="number" placeholder="Athlete Temperature" max="110" min="0" step=".1">
              </div>
            </div>
          </div>   -->
          <div class="field is-horizontal">
            <div class="field-label is-small">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Dog</label>
            </div>
            <div class="field-body">
              <div class="field">
                <select class="input is-primary is-small" bind:value={formModel.dog} required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button id="btn-form-submit" class="button is-primary is-small" type="submit" disabled={register_loading}>Register</button>
            </div>
            <div class="control">
              <button type="button" class="button is-primary is-light is-small" on:click={() => cancelForm()}>Clear</button>
            </div>
          </div>
        </form>
      </div>
  </div>

  <div class="column is-9">
    <div class="box">
      {#if alert_message}
        <div class="notification is-light is-{alert_message_type}">{@html alert_message}</div>
      {/if}
      {#if athlete_loading}
        <progress class="progress is-primary" max="100">30%</progress>
      {:else}
        {#if showList === 'registered'}
          <h6 class="title is-6">Registered Athletes</h6>
          {#if items_data.length > 0}
            <div class="field is-grouped">
              <p class="control">
                {#if items_total > 0}
                <span class="tag is-primary is-medium">Total : {items_total}</span>
                <span class="tag is-info is-medium">Registered : {items_registered}</span>
                <span class="tag is-success is-medium">Started : {items_started}</span>
                {/if}
              </p>
              <p class="control is-expanded">
                <input id="reg_filter_text" name="reg_filter_text" class="input is-small" type="text" bind:value={search} placeholder="Search by Name, Surname, Race Number">
              </p>
              <p class="control">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-warning is-small" on:click={() => search = ''}>
                  Clear
                </a>
              </p>
            </div>
            <SvelteTable 
              columns={columns} 
              rows={filteredList} 
              classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
            >
              <tr slot="row" let:row let:n>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.name}</td>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.surname}</td>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.activity_name}</td>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.race_number}</td>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.gender}</td>
                <td class="small-cell" on:click={() => selectAthlete(row, 'reselect')}>{row.category}</td>
                <td class="small-cell" style="text-align: center;" on:click={() => selectAthlete(row, 'reselect')}>R {row.paid_amount}</td>
                <td class="small-cell" style="text-align: center;" on:click={() => selectAthlete(row, 'reselect')}> 
                  {#if row.start}
                   {convertDateTimeToTime(row.start,2)}
                  {/if}
                </td>
                {#if checkSelected(row) === 1}
                  <td class="small-cell has-background-primary" style="text-align: center;" on:click={() => removeFromSpecialStart(row)}>
                    <span class="icon is-small">
                      <i class="fa fa-trash"></i>
                    </span>
                  </td>
                {:else if checkSelected(row) === 0}
                  <td class="small-cell has-background-white" style="text-align: center;" on:click={() => selectForSpecialStart(row)}>
                    <span class="icon is-small">
                      <i class="fa fa-clock-o"></i>
                    </span>
                  </td>
                {:else}
                  <td class="small-cell-nopointer has-background-white" style="text-align: center;"></td>
                {/if}
              </tr>
            </SvelteTable>  
            <!-- {#if items_total > 25}
              <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                <a class="pagination-previous" on:click={() => paginate(items_page-1)}>Previous</a>
                <a class="pagination-next" on:click={() => paginate(items_page+1)}>Next page</a>
                <ul class="pagination-list">
                  {#each Array(items_pages) as _, i}
                    <li><a class="pagination-link {items_page === i ? 'is-current' : ''}" aria-label="Goto page {i}" on:click={() => paginate(i)}>{i+1}</a></li>
                  {/each}
                </ul>
              </nav>
            {/if} -->
          {:else}
            <div class="notification is-warning">
              No registered athletes for event.
            </div>
          {/if}
        {/if}
        {#if showList === 'search'}
          <h5 class="title is-5">Athlete Search</h5>
          {#if search_data.length > 0}
            <SvelteTable columns={columns_search} rows={search_data} classNameTable={['table is-hoverable is-narrow']}>
              <tr slot="row" let:row let:n>
                <td>{row.name}</td>
                <td>{row.surname}</td>
                <td>{row.gender}</td>
                <td>{row.age}</td>
                <td>{row.id_number}</td>
                <td style="text-align: center;">
                  <button type="button" class="button is-small is-primary" on:click={() => selectAthlete(row, 'select')}>
                    <span class="icon is-small">
                      <i class="fa fa-edit"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </SvelteTable>
          {:else}
            <div class="notification is-warning">
              No Athlete Found for Current Search Criteria.
            </div>  
          {/if}
          <button type="button" class="button is-info" on:click={() => showList = 'registered'}>Back</button>
        {/if}
      {/if}
    </div>
  </div>
</div>
<div class="modal" class:is-active="{showStartModal}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Start Race</p>
      <button type="button" class="delete" aria-label="close" on:click={() => showStartModal = false}></button>
    </header>
    <section class="modal-card-body">
      {#if alert_message_start}
        <div class="notification is-light is-danger">{@html alert_message_start}</div>
      {/if}
      <table class="table is-narrow is-fullwidth">
        <tr><th>Activities</th><th></th><th>Start</th></tr>
        {#each activities as act}
          <tr>
            <td>{act.name}</td>
            <td><input name="cb_start_act" type="checkbox" on:change={(e) => selectStartActivity(e, act.id)}/></td>
            <td>
              {#if act.start}
                 {convertDateTimeToTime(act.start,2)}
              {/if}
            </td>
          </tr>
        {/each}
      </table>
      <Clock />
    </section>
    <footer class="modal-card-foot">
      {#if selectedStartActivites.length > 0 && !start_loading}
        <button class="button is-success is-small" type="button" on:click={() => startActivities()}>Start</button>
      {/if}
      <button class="button is-small" type="button" on:click={() => showStartModal = false}>Close</button>
    </footer>
  </div>
</div>

<div class="modal" class:is-active="{showSpecialStartModal}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Group Start</p>
      <button type="button" class="delete" aria-label="close" on:click={() => showSpecialStartModal = false}></button>
    </header>
    <section class="modal-card-body">
      {#if alert_message_start_special}
        <div class="notification is-light is-danger">{@html alert_message_start_special}</div>
      {/if}
      <SvelteTable 
        columns={columns} 
        rows={special_start} 
        classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
      >
        <tr slot="row" let:row let:n>
          <td class="small-cell">{row.name}</td>
          <td class="small-cell">{row.surname}</td>
          <td class="small-cell">{row.activity_name}</td>
          <td class="small-cell">{row.race_number}</td>
          <td class="small-cell">{row.gender}</td>
          <td class="small-cell">{row.category}</td>
          <td class="small-cell">R {row.paid_amount}</td>
          <td class="small-cell"></td>
          <td class="small-cell has-background-primary" style="text-align: center;" on:click={() => removeFromSpecialStart(row)}>
            <span class="icon is-small">
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </tr>
      </SvelteTable>
      <Clock />
    </section>
    <footer class="modal-card-foot">
      {#if special_start.length > 0 && !start_loading}
        <button class="button is-success is-small" type="button" on:click={() => startGroup()}>Group Start</button>
      {/if}
      <button class="button is-small" type="button" on:click={() => showSpecialStartModal = false}>Close</button>
    </footer>
  </div>
</div>