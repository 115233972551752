export function convertDateTime(datetime, hours) {
  let dt = new Date(datetime);
  dt.setHours(dt.getHours() + hours);
  let dateOnly = dt.toISOString().substring(0, 10);
  let timeOnly = dt.toISOString().substring(11, 16);

  return dateOnly + " " + timeOnly;
}

export function convertDate(datetime, hours) {
  let dt = new Date(datetime);
  dt.setHours(dt.getHours() + hours);
  let dateOnly = dt.toISOString().substring(0, 10);

  return dateOnly;
}

export function convertDateTimeToTime(datetime, hours) {
  let dt = new Date(datetime);
  dt.setHours(dt.getHours() + hours);
  let timeOnly = dt.toISOString().substring(11, 16);
  return timeOnly;
}

export function convertDateTimeStringToTime(datetime) {
  let timeOnly = datetime.substring(11, 16);
  return timeOnly;
}

export function convertDateTimeHTML5(datetime, hours) {
  let dt = new Date(datetime);
  dt.setHours(dt.getHours() + hours);
  return dt.toISOString().substring(0, 19);
}
