<script>
  import VenuesMenu from "../../../components/Side_Menu.svelte";
  import client from "../../../client";
  import { onMount } from 'svelte';
  import SvelteTable from "svelte-table";
  import onScan from "onscan.js";

  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    //   listVenues();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  $: if(show=='list') { listVenues(); }

  let show = "list";
  let venue_message;
  let venue_message_type = 'info';
  let venue_loading = true;

  // List Variables / Functions
  let venues_data = [];
  let venues_total = 0;
  let venues_items = 0;
  let venues_skip = 0;

  async function listVenues() {
    venue_loading = true;
    let venues = await client.service('venues').find({
      query: {
        $sort: { name: 1 },
        $limit: 25
      }
    });

    venues_data = venues.data;
    venues_total = venues.total;
    venues_items = venues.items;
    venues_skip = venues.skip;
    venue_loading = false;
  }

  const columns = [
  {
    key: "name",
    title: "NAME",
    value: v => v.name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      // use first letter of first_name to generate filter
      let letrs = {};
      rows.forEach(row => {
        let letr = row.name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.name.charAt(0).toLowerCase()
  },
  {
    key: "enabled",
    title: "ENABLED",
    value: v => v.enabled,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "",
    title: "ACTION",
    value: "",
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  }
];

// Form Variables / Functions

let venue_name;
let venue_enabled = true;
let venue_index = -1;

async function onSubmit() {
  venue_message = "";
  venue_message_type = "info";
  if (venue_name.length > 5) {
    var splitStr = venue_name.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    venue_name = splitStr.join(' ');
    venue_loading = true;
    if (venue_index == -1) {
      try {
        let status = await client.service('venues').create({
          name: venue_name,
          enabled: venue_enabled
        });
        venue_name = "";
        venue_enabled = true;
        await listVenues();
        show = 'list';
        venue_message = "Venue added successfully";
        venue_message_type = "success";
      } catch(err) {
        venue_message = err;
        venue_message_type = "danger";
        venue_loading = false;
      }
    } else {
      try {
        let status = await client.service('venues').update(venue_index,{
          name: venue_name,
          enabled: venue_enabled
        });
        venue_name = "";
        venue_enabled = true;
        venue_index = -1
        await listVenues();
        show = 'list';
        venue_message = "Venue updated successfully";
        venue_message_type = "success";
      } catch(err) {
        venue_message = err;
        venue_message_type = "danger";
        venue_loading = false;
      }
    }
  } else {
    venue_message = "Venue Name must be more than 5 characters";
    venue_message_type = "danger";
  }
}

function selectItem(id, name, enabled) {
  venue_message = "";
  venue_message_type = "info";
  venue_index = id;
  venue_name = name;
  venue_enabled = enabled;
  show = 'add';
}

function cancelForm() {
  venue_message = "";
  venue_message_type = "info";
  venue_name = "";
  venue_index = -1;
  venue_enabled = true;
  show = 'list';
}

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-3">
  <VenuesMenu bind:show={show} bind:alert_message={venue_message} menu_heading="Venue"/>
</div>

<div class="column is-9">
  <div class="box">
    {#if venue_message}
      <div class="notification is-light is-{venue_message_type}">{venue_message}</div>
    {/if}
    {#if venue_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      {#if show === 'add'}
        <div class="content">
          <h1 class="title">
            {#if venue_index == -1}
              Add Venue
            {:else}
              Edit Venue
            {/if}
          </h1>
        </div>
        <form
          on:submit|preventDefault="{onSubmit}"
        >
          <div class="field">
            <label class="label" for="venue_name">Venue Name</label>
            <div class="control">
              <input class="input is-primary" id="venue_name" bind:value={venue_name} type="text" placeholder="Venue Name" required minlength="6">
            </div>
          </div>
          <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => venue_enabled = !venue_enabled}>
            {#if venue_enabled}
              <p class="control">
                <span class="icon">
                  <i class="fa fa-check-square fa-2x"></i>
                </span>   
              </p>
              <p class="control" style="margin-left: 10px; font-size: large;"> Enabled</p>  
              {:else}
              <p class="control">
                <span class="icon">
                  <i class="fa fa-square fa-2x"></i>
                </span>   
              </p>
              <p class="control" style="margin-left: 10px; font-size: large;"> Disabled</p>  
              {/if}
          </div>  
          <div class="field is-grouped">
            <div class="control">
              <button id="btn-form-submit" class="button is-primary" type="submit">Save</button>
            </div>
            <div class="control">
              <button type="button" class="button is-primary is-light" on:click={() => cancelForm()}>Cancel</button>
            </div>
          </div>
        </form>
      {:else}
        {#if venues_data.length > 0}
          <SvelteTable columns={columns} rows={venues_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
            <tr slot="row" let:row let:n>
              <td>{row.name}</td>
              <td style="text-align: center;">
                {#if row.enabled}
                  <span class="has-text-success icon">
                    <i class="fa fa-check-square"></i>
                  </span>
                {:else}
                <span class="has-text-danger icon">
                  <i class="fa fa-square"></i>
                </span>
                {/if}
              </td>
              <td style="text-align: center;">
                <button class="button is-small is-primary" on:click={() => selectItem(row.id, row.name, row.enabled)}>
                  <span class="icon is-small">
                    <i class="fa fa-edit"></i>
                  </span>
                </button>
              </td>
            </tr>
          </SvelteTable>  
          {:else}
          <div class="notification is-warning">
              No Venues Loaded    
            </div>
          {/if}
      {/if}
    {/if}
  </div>
</div>

