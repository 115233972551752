// import Index from "./views/public/Login.svelte";
import PublicLayout from "./views/public/layout_public.svelte";
import PublicIndex from "./views/public/index.svelte";
import AdminLayout from "./views/admin/layout_admin.svelte";
import AdminIndex from "./views/admin/index.svelte";
import VenuesIndex from "./views/admin/venues/index.svelte";
import ActivityIndex from "./views/admin/activity/index.svelte";
import EventsIndex from "./views/admin/events/index.svelte";
import AthletesIndex from "./views/admin/athletes/index.svelte";
import RegisterIndex from "./views/admin/register/index.svelte";
import RegisterStartIndex from "./views/admin/register/withstart.svelte";
import StartIndex from "./views/admin/start/index.svelte";
import RaceIndex from "./views/admin/race/index.svelte";
import ImportIndex from "./views/admin/import/index.svelte";
import ExportIndex from "./views/admin/export/index.svelte";
import FinalIndex from "./views/admin/finalise/index.svelte";
import StageIndex from "./views/admin/stage/index.svelte";
import ChallengeIndex from "./views/admin/challenge/index.svelte";
// Error Pages
import Error404 from "./views/error404.svelte";

import user from "./stores/user";
import { get } from "svelte/store";

function userIsAdmin() {
  const userStore = get(user);
  if (userStore.id != -1 && userStore.level !== "") return true;
  else return false;
}

const routes = [
  {
    name: "404",
    path: "404",
    component: Error404,
  },
  {
    name: "/",
    component: PublicIndex,
    layout: PublicLayout,
  },
  //   { name: "login", component: Login, layout: PublicLayout },
  {
    name: "admin",
    onlyIf: { guard: userIsAdmin, redirect: "/" },
    nestedRoutes: [
      {
        name: "index",
        component: AdminIndex,
        layout: AdminLayout,
      },
      {
        name: "venues",
        component: VenuesIndex,
        layout: AdminLayout,
      },
      {
        name: "activity",
        component: ActivityIndex,
        layout: AdminLayout,
      },
      {
        name: "challenge",
        component: ChallengeIndex,
        layout: AdminLayout,
      },
      {
        name: "events",
        component: EventsIndex,
        layout: AdminLayout,
      },
      {
        name: "athletes",
        component: AthletesIndex,
        layout: AdminLayout,
      },
      {
        name: "register/:id",
        component: RegisterIndex,
        layout: AdminLayout,
      },
      {
        name: "register2/:id",
        component: RegisterStartIndex,
        layout: AdminLayout,
      },
      {
        name: "start/:id",
        component: StartIndex,
        layout: AdminLayout,
      },
      {
        name: "race/:id",
        component: RaceIndex,
        layout: AdminLayout,
      },
      {
        name: "import",
        component: ImportIndex,
        layout: AdminLayout,
      },
      {
        name: "export",
        component: ExportIndex,
        layout: AdminLayout,
      },
      {
        name: "finalise",
        component: FinalIndex,
        layout: AdminLayout,
      },
      {
        name: "stage",
        component: StageIndex,
        layout: AdminLayout,
      },
    ],
  },
];

export { routes };
