<script>
  import client from "../../../client";
  import { convertDateTimeToTime } from "../../../utils/dates";
  import { validateScanCode } from "../../../utils/strings";
  import { navigateTo, Navigate } from "svelte-router-spa";
  import { onMount } from 'svelte';
  import Clock from "../../../components/Clock.svelte";
  import SvelteTable from "svelte-table";
  import onScan from "onscan.js";
  
  export let currentRoute;
  export let params = {params};

  //Register to listen for new registered athletes
  client.service('race').on("created", async (race) => {
    if (race.event_id === raceEvent.id) {
      registeredAthletes(true);
    }
  });
  client.service('race').on("patched", async (race) => {
    if (race.event_id === raceEvent.id) {
      if (race.status !== 'finish') {
        registeredAthletes(true);
      }
    }
  });
  client.service('events').on("patched", async (raceEventTmp) => {
    if (raceEventTmp.id === raceEvent.id) {
      raceEvent = raceEventTmp;
      activities = JSON.parse(raceEvent.activities);
    }
  });

  onMount(async () => {
    if (currentRoute.namedParams.id) {
      try {
        raceEvent = await client.service('events').get(parseInt(currentRoute.namedParams.id));
        if (!raceEvent) {
          navigateTo('/');
        } else {
          /* Remove Scanning */
          if (onScan.isAttachedTo(document)) { 
            onScan.detachFrom(document); 
          }

          // Enable scan events for the entire document
          onScan.attachTo(document, {
            reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
            onScan: async function(sScancode) { // Alternative to document.addEventListener('scan')
                if (validateScanCode(sScancode)) {
                  let scannumber = items_data.filter(x => x.race_number==sScancode);
                  if (scannumber.length > 0) {
                    selectForSpecialStart(scannumber[0]);
                  }
                } else {
                  audio.play();
                }
            },
            scanError: function(error) {
              console.error(error);
            },
          });

          activities = JSON.parse(raceEvent.activities);
          activities.forEach(act => {
            activitiesSelect.push({label: act.name+" [R"+act.price+"]", value: act.id, amount: act.price});
          });
          activitiesSelect = activitiesSelect;
          registeredAthletes(true);
        }
      } catch (err) {
        console.error(err);
        navigateTo('/');
      }
    }
  });

  // Page Variables
  let alert_message;
  let alert_message_type = 'info';
  let alert_message_start;
  let alert_message_start_special;
  let athlete_loading = false;
  let raceEvent = { name : ""};
  let activities = [];
  let activitiesSelect = [];
  let selectedStartActivites = [];
  let start_loading = false;

  // Registered Athletes
  let items_data = [];
  let items_total = 0;
  let items_started = 0;
  let items_registered = 0;

  // Search Athletes
  let search = "";
  
  // Special Start
  let special_start = [];

  async function registeredAthletes(refreshlist) {
    athlete_loading = true;

    if (refreshlist) {
      let items = await client.service('race').find({
        query: {
          $and : [
            {event_id : raceEvent.id},
            {race_number : { $notlike : 'PRE-%' }},
            {status : { $ne : 'pre-entry' }},
            {start : null},
          ],
          $sort: { id: 0 },
        }
      });

      // console.log(items);
      items_data = items;
      items_total = items.length;
      // items_skip = items.skip;
      // items_pages = Math.floor(items_total/items_limit);
      items_data = items_data;
    }

    let countRegistered = await client.service('comp').find({
      query: {
        $and : [
          {event_id : raceEvent.id},
          {race_number: { $notlike: 'PRE-%'}},
          {status: { $ne: 'pre-entry'}},
        ],
        $limit:0
      }
    });

    let countStarted = await client.service('comp').find({
      query: {
        $and : [
          {event_id : raceEvent.id},
          {start: { $ne : null}},
          {race_number: { $notlike: 'PRE-%'}},
          {status: { $ne: 'pre-entry'}},
        ],
        $limit:0
      }
    });

    items_started = countStarted.total;
    items_registered = countRegistered.total;
    athlete_loading = false;
  }

  //Filter Registered Athletes on name, surname and race_number
  $: filteredList = items_data.filter(item => (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.surname.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.race_number.toLowerCase().indexOf(search.toLowerCase()) !== -1));

  const columns = [
    {
      key: "id",
      title: "Reg",
      value: v => v.id,
      sortable: false,
      headerClass: "text-center",
    },
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "surname",
      title: "Surname",
      value: v => v.surname,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "activity_name",
      title: "Activity",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "race_number",
      title: "Race Number",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "gender",
      title: "Gender",
      value: v => v.gender,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "category",
      title: "Category",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "special_start",
      title: "o",
      value: v => v.special_start,
      sortable: false,
      headerClass: "text-center",
    }
  ];

  const columns_special_start = [
    {
      key: "name",
      title: "Name",
      value: v => v.name,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "activity_name",
      title: "Activity",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "race_number",
      title: "Number",
      value: v => v.category,
      sortable: false,
      headerClass: "text-left",
    },
    {
      key: "special_start",
      title: "o",
      value: v => v.special_start,
      sortable: false,
      headerClass: "text-center",
    }
  ];

  function selectStartActivity(e,id) {
    alert_message_start = null;
    selectedStartActivites = selectedStartActivites.filter(function(value, index, arr){
        return value != id;
    });
    if (e.target.checked) {
      selectedStartActivites.push(id);
    }
  }

  async function startActivities() {
    if (selectedStartActivites.length > 0) {
      try {
        alert_message_start = "";
        // let start_time = convertDateTimeHTML5(new Date(),2);
        let servertime = await client.service("email/time").find();
        let start_time = servertime.datetime;
        for (let index = 0; index < selectedStartActivites.length; index++) {
          // service.patch(null, {number: 456}, { query: { text: 'Test 1' }});
          let status = await client.service('race').patch(null,
          {
            start : start_time
          },
          {
            query : {
                event_id : raceEvent.id,
                activity_id : selectedStartActivites[index],
                special_start : false,
                status: {
                  $nin: [ 'pre-entry', 'finished' ]
                }
            }
          });
          for (let index2 = 0; index2 < activities.length; index2++) {
            let act = activities[index2];
            if (selectedStartActivites[index] == act.id) {
              act.start = start_time;
            }
          }
          activities = activities;
          status = await client.service('events').patch(raceEvent.id,
          {
            activities : JSON.stringify(activities)
          });
        }
        activities = activities;
        raceEvent = raceEvent;
        selectedStartActivites = [];
        let checkboxes = document.getElementsByName('cb_start_act');
        for(var i=0, n=checkboxes.length;i<n;i++) {
          checkboxes[i].checked = false;
        }
      } catch(err) {
        console.error(err);
        alert_message_start = err;
      }
    } else {
      alert_message_start = "Please select atleast one activity!";
    }
  }

  async function startGroup() {
    if (special_start.length > 0) {
      try {
        alert_message_start_special = "";
        // let start_time = convertDateTimeHTML5(new Date(),2);
        let servertime = await client.service("email/time").find();
        let start_time = servertime.datetime;
        for (let index = 0; index < special_start.length; index++) {
          // service.patch(null, {number: 456}, { query: { text: 'Test 1' }});

          let status = await client.service('race').patch(special_start[index].id,
          {
            start : start_time,
            special_start : true
          });
          for (let index2 = 0; index2 < activities.length; index2++) {
            let act = activities[index2];
            if (selectedStartActivites[index] == act.id) {
              act.start = start_time;
            }
          }
          activities = activities;
          status = await client.service('events').patch(raceEvent.id,
          {
            activities : JSON.stringify(activities)
          });
        }
        special_start = [];
      } catch(err) {
        console.error(err);
        alert_message_start_special = err;
      }
    } else {
      alert_message_start_special = "Please select atleast one activity!";
    }
  }

  function selectForSpecialStart(athlete) {
    special_start.push(athlete);
    special_start = special_start;
    filteredList = filteredList;
  }

  function removeFromSpecialStart(athlete) {
    special_start = special_start.filter(function(el) { return el.id != athlete.id; })
    filteredList = filteredList;
  }

  function checkSelected(row) {
    let found = special_start.find( ({ id }) => id === row.id );
    // console.log(row);
    if (row.start || row.status === "pre-entry" || row.status === "finished")
      return 2;

    if (found) {
      return 1;
    } else {
      return 0;
    }
  }

  function registerAthlete(id) {
    navigateTo('/admin/register/'+raceEvent.id+'?athlete='+id);
  }

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }
  div :global(.text-left) {
    text-align: left;
    font-weight: bold;
    font-size: small;
    padding: 2px;
  }

  .small-cell {
    font-size: 0.75rem;
    padding: 2px;
    cursor: pointer;
  }

  .small-cell-nopointer {
    font-size: 0.75rem;
    padding: 2px;
  }
</style>
<div class="columns is-multiline">
  <div class="column is-full">
    <div class="box">
      <div class="columns">
        <div class="column is-8">
          <span class="tag is-dark is-large">{raceEvent.name}</span>
        </div>
        <div class="column is-2">
          <Navigate
          to="admin/register/{raceEvent.id}"
          styles="button is-small is-info"
        >Register</Navigate>  
        <Navigate
                      to="admin/race/{raceEvent.id}"
                      styles="button is-small is-primary"
                    >Race</Navigate>
        </div>
        <div class="column is-2">
          <Clock />
        </div>
      </div>
    </div>
  </div>
  <div class="column is-4">
    <div class="box">
      <div class="box has-background-white-ter text-center p-2">
        <h6 class="title is-6 mb-1">Group Start</h6>
        {#if alert_message_start_special}
          <div class="notification is-light is-danger">{@html alert_message_start_special}</div>
        {/if}
        <SvelteTable 
          columns={columns_special_start} 
          rows={special_start} 
          classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
        >
          <tr slot="row" let:row let:n>
            <td class="small-cell">{row.name} {row.surname}</td>
            <td class="small-cell">{row.activity_name}</td>
            <td class="small-cell">{row.race_number}</td>
            <td class="small-cell has-background-primary" style="text-align: center;" on:click={() => removeFromSpecialStart(row)}>
              <span class="icon is-small">
                <i class="fa fa-trash"></i>
              </span>
            </td>
          </tr>
        </SvelteTable>
        {#if special_start.length > 0 && !start_loading}
          <button class="button is-success is-small is-fullwidth" type="button" on:click={() => startGroup()}>Start Group</button>
        {/if}
      </div>
      <div class="box has-background-white-ter text-center p-2">
        <h6 class="title is-6 mb-1">Activity Start</h6>
        {#if alert_message_start}
          <div class="notification is-light is-danger">{@html alert_message_start}</div>
        {/if}
        <table class="table is-narrow is-fullwidth">
          <tr><th>Activities</th><th></th><th style="text-align:center;">Start Time</th></tr>
          {#each activities as act}
            <tr>
              <td>{act.name}</td>
              <td style="text-align:center;"><input name="cb_start_act" type="checkbox" on:change={(e) => selectStartActivity(e, act.id)}/></td>
              <td>
                {#if act.start}
                   {convertDateTimeToTime(act.start,2)}
                {/if}
              </td>
            </tr>
          {/each}
        </table>
        {#if selectedStartActivites.length > 0 && !start_loading}
          <button class="button is-success is-small is-fullwidth" type="button" on:click={() => startActivities()}>Start Activity</button>
        {/if}
      </div>
    </div>
  </div>
  <div class="column is-8">
    <div class="box">
      {#if alert_message}
        <div class="notification is-light is-{alert_message_type}">{@html alert_message}</div>
      {/if}
      {#if athlete_loading}
        <progress class="progress is-primary" max="100">30%</progress>
      {:else}
          <h6 class="title is-6">Registered Athletes Not Started</h6>
          {#if items_data.length > 0}
            <div class="field is-grouped">
              <p class="control">
                {#if items_total > 0}
                <span class="tag is-primary is-medium">Not Started : {items_total}</span>
                <span class="tag is-info is-medium">Registered : {items_registered}</span>
                <span class="tag is-success is-medium">Started : {items_started}</span>
                {/if}
              </p>
              <p class="control is-expanded">
                <input id="reg_filter_text" name="reg_filter_text" class="input is-small" type="text" bind:value={search} placeholder="Search by Name, Surname, Race Number">
              </p>
              <p class="control">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-warning is-small" on:click={() => search = ''}>
                  Clear
                </a>
              </p>
            </div>
            <SvelteTable 
              columns={columns} 
              rows={filteredList} 
              classNameTable={['table is-bordered is-striped is-narrow is-hoverable is-fullwidth']} 
            >
              <tr slot="row" let:row let:n>
                <td class="small-cell" style="text-align:center" on:click={() => registerAthlete(row.id)}><i class="fa fa-edit"></i></td>
                {#if checkSelected(row) === 1}
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.name}</td>
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.surname}</td>
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.activity_name}</td>
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.race_number}</td>
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.gender}</td>
                  <td class="small-cell" on:click={() => removeFromSpecialStart(row)}>{row.category}</td>
                  <td class="small-cell has-background-primary" style="text-align: center;" on:click={() => removeFromSpecialStart(row)}>
                    <span class="icon is-small">
                      <i class="fa fa-trash"></i>
                    </span>
                  </td>
                {:else if checkSelected(row) === 0}
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.name}</td>
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.surname}</td>
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.activity_name}</td>
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.race_number}</td>
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.gender}</td>
                  <td class="small-cell" on:click={() => selectForSpecialStart(row)}>{row.category}</td>
                  <td class="small-cell has-background-white" style="text-align: center;" on:click={() => selectForSpecialStart(row)}>
                    <span class="icon is-small">
                      <i class="fa fa-clock-o"></i>
                    </span>
                  </td>
                {:else}
                  <td class="small-cell-nopointer">{row.name}</td>
                  <td class="small-cell-nopointer">{row.surname}</td>
                  <td class="small-cell-nopointer">{row.activity_name}</td>
                  <td class="small-cell-nopointer">{row.race_number}</td>
                  <td class="small-cell-nopointer">{row.gender}</td>
                  <td class="small-cell-nopointer">{row.category}</td>
                  <td class="small-cell-nopointer has-background-white" style="text-align: center;"></td>
                {/if}
              </tr>
            </SvelteTable>  
          {:else}
            <div class="notification is-warning">
              No registered athletes with no start times for event.
            </div>
          {/if}
        {/if}
    </div>
  </div>
</div>