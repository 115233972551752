<div class="column is-3">
  <aside class="menu is-hidden-mobile">
    <nav class="panel p-3">
    <p class="menu-label">General</p>
    <ul class="menu-list">
      <li><a href="/admin">Dashboard</a></li>
      <li><a href="/admin/athletes">Athletes</a></li>
      <li><a href="/admin/venues">Venues</a></li>
      <li><a href="/admin/activity">Activities</a></li>
      <li><a href="/admin/events">Events</a></li>
      <li><a href="/admin/import">Pre-entries</a></li>
      <li><a href="/admin/export">Export</a></li>
      <li><a href="/admin/finalise">Finalise</a></li>
      <li><a href="/admin/challenge">Challenges</a></li>
    </ul>
    </nav>
  </aside>
</div>
