<script>
  import ItemMenu from "../../../components/Side_Menu.svelte";
  import client from "../../../client";
  import { onMount } from 'svelte';
  import SvelteTable from "svelte-table";
  import Select from 'svelte-select';
  import { convertDateTime, convertDateTimeHTML5 } from "../../../utils/dates";
  import * as animateScroll from "svelte-scrollto";
  import onScan from "onscan.js";
  
  export let currentRoute = {currentRoute};
  export let params = {params};

  onMount(async () => {
    // listItems();
    listVenues();
    if (onScan.isAttachedTo(document)) { onScan.detachFrom(document); }
  });

  $: if(show=='list') { listItems(); }

  //Venues for event
  let venue;
  let venue_list = [];

  //Activities for Venue
  let activity;
  let activity_list = [];
  let activity_price = 0.00;

  async function listVenues() {
    item_loading = true;
    let items = await client.service('venues').find({
      query: {
        enabled: true,
        $select: [ 'id', 'name' ],
        $sort: { name: 1 },
        $limit: 500
      }
    });

    venue_list = [];
    items.data.forEach(i => {
      venue_list.push({value:i.id, label: i.name})
    });
    item_loading = false;
  }

  //Page Data
  let show = "list";
  let item = 'Event';
  let item_message;
  let item_message_type = 'info';
  let item_loading = true;
  
  // List Variables / Functions
  let items_data;
  let items_total = 0;
  let items_limit = 0;
  let items_skip = 0;

  async function listItems() {
    // if (venue && (show === 'list')) {
      item_loading = true;
      let items = await client.service('events').find({
        query: {
          $sort: { start_date: 0 },
          $limit: 25
        }
      });

      if (items.total > 0) {
        items_data = items.data;
        items_total = items.total;
        items_limit = items.limit;
        items_skip = items.skip;
      } else {
        items_data = [];
        items_total = 0;
        items_limit = 0;
        items_skip = 0;
      }
      item_loading = false;
    // }
  }

  async function listActivites() {
    item_loading = true;
    let items = await client.service('activity').find({
      query: {
        venue_id: venue.value,
        enabled: true,
        $sort: { name: 1 },
        $limit: 25
      }
    });

    activity_list = [];
    items.data.forEach(i => {
      activity_list.push({value:i.id, label: i.name})
    });
    item_loading = false;
  }

  const columns = [
  {
    key: "name",
    title: "Name",
    value: v => v.name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      // use first letter of first_name to generate filter
      let letrs = {};
      rows.forEach(row => {
        let letr = row.name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.name.charAt(0).toLowerCase()
  },
  {
    key: "venue_name",
    title: "Venue",
    value: v => v.venue_name,
    sortable: true,
    headerClass: "text-left",
    class: "text-left",
    filterOptions: rows => {
      // use first letter of first_name to generate filter
      let letrs = {};
      rows.forEach(row => {
        let letr = row.venue_name.charAt(0);
        if (letrs[letr] === undefined)
          letrs[letr] = {
            name: `${letr.toUpperCase()}`,
            value: letr.toLowerCase()
          };
      });
      // fix order
      letrs = Object.entries(letrs)
        .sort()
        .reduce((o, [k, v]) => ((o[k] = v), o), {});
      return Object.values(letrs);
    },
    filterValue: v => v.venue_name.charAt(0).toLowerCase()
  },
  {
    key: "enabled",
    title: "",
    value: v => v.enabled,
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  },
  {
    key: "start_date",
    title: "Date",
    value: v => v.start_date,
    sortable: false,
    headerClass: "text-center",
    class: "text-center",
  },
  {
    key: "",
    title: "",
    value: "",
    sortable: false,
    headerClass: "text-center",
    class: "text-center"
  }
];

// Form Variables / Functions

let formModel = {
  index : -1,
  name : "",
  start_date : new Date().toISOString().substring(0,16),
  finish_date : new Date().toISOString().substring(0,16),
  enabled : true,
  email : true,
  live : true,
  company_id : 1,
  activities : []
}

async function onSubmit() {
  item_message = "";
  item_message_type = "info";
  if (formModel.name.length > 5 && venue.value && formModel.start_date && formModel.finish_date && formModel.activities.length > 0) {
    var splitStr = formModel.name.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    formModel.name = splitStr.join(' ');
    item_loading = true;
    if (formModel.index == -1) {
      try {
        let status = await client.service('events').create({
          name: formModel.name,
          start_date: formModel.start_date,
          finish_date: formModel.finish_date,
          enabled: formModel.enabled,
          email: formModel.email,
          live: formModel.live,
          venue_id: venue.value,
          company_id: formModel.company_id,
          activities: JSON.stringify(formModel.activities),
        });
        formModel.name = "";
        formModel.enabled = true;
        formModel.start_date = new Date();
        formModel.finish_date = new Date();
        formModel.email = true;
        formModel.live = true;
        formModel.company_id = 0;
        formModel.activities = [];
        venue = null;
        show = 'list';
        await listItems();
        item_message = item+" added successfully";
        item_message_type = "success";
      } catch(err) {
        item_message = err;
        item_message_type = "danger";
        item_loading = false;
        animateScroll.scrollToTop();
      }
    } else {
      try {
        let status = await client.service('events').update(formModel.index,{
          name: formModel.name,
          start_date: formModel.start_date,
          finish_date: formModel.finish_date,
          enabled: formModel.enabled,
          email: formModel.email,
          live: formModel.live,
          venue_id: venue.value,
          company_id: formModel.company_id,
          activities: JSON.stringify(formModel.activities),
        });
        formModel.name = "";
        formModel.enabled = true;
        formModel.start_date = new Date();
        formModel.finish_date = new Date();
        formModel.email = true;
        formModel.live = true;
        formModel.company_id = 0;
        formModel.activities = [];
        venue = null;
        show = 'list';
        await listItems();
        item_message = item+" updated successfully";
        item_message_type = "success";
      } catch(err) {
        console.error(err);
        item_message = err;
        item_message_type = "danger";
        item_loading = false;
        animateScroll.scrollToTop();
      }
    }
  } else {
    item_message = "<div class='content'><ol type'1'><li>Name must be more than 5 characters</li><li>Please select a venue</li><li>Please add activities</li></ol></div>";
    item_message_type = "danger";
    animateScroll.scrollToTop();
  }
}

async function selectItem(id, name, start_date, finish_date, enabled, email, live, venue_id, venue_name, company_id, activities) {
  item_message = "";
  item_message_type = "info";
  formModel.index = id;
  formModel.name = name;
  formModel.enabled = enabled;
  formModel.start_date = convertDateTimeHTML5(start_date,2);
  formModel.finish_date = convertDateTimeHTML5(finish_date,2);
  formModel.email = email;
  formModel.live = live;
  formModel.company_id = company_id;
  formModel.activities = JSON.parse(activities);
  venue = { value: venue_id, label: venue_name };
  listVenues();
  listActivites();
  show = 'add';
}

function cancelForm() {
  item_message = "";
  item_message_type = "info";
  formModel.name = "";
  formModel.index = -1;
  formModel.enabled = true;
  show = 'list';
}

function addActivity() {
  if (formModel.activities.filter(e => e.name === activity.label).length == 0) {
    if (activity && activity_price > 0) {
      formModel.activities = [...formModel.activities, {name:activity.label, id: activity.value, price:activity_price, start:""}];
      activity = null;
      activity_price = 0;
    }
  }
}

function removeActivity(index) {
  formModel.activities.splice(index, 1);
  formModel.activities = formModel.activities;
}

</script>

<style>
  /* Table Styles */
  div :global(.text-center) {
    text-align: center;
  }
  div :global(.text-left) {
    text-align: left;
  }
</style>

<div class="column is-3">
  <ItemMenu bind:show={show} bind:alert_message={item_message} menu_heading={item}/>
</div>

<div class="column is-9">
  <div class="box">
    {#if item_message}
      <div class="notification is-light is-{item_message_type}">{@html item_message}</div>
    {/if}
    {#if item_loading}
      <progress class="progress is-primary" max="100">30%</progress>
    {:else}
      {#if show === 'add'}
        <div class="content">
          <h1 class="title">
            {#if formModel.index == -1}
              Add {item}
            {:else}
              Edit {item}
            {/if}
          </h1>
        </div>
        <form
          on:submit|preventDefault="{onSubmit}"
        >
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">Venue</label>
            <div class="control">
              <div class="select is-fullwidth">
                <Select items={venue_list} bind:selectedValue={venue} on:select={() => listActivites()} isClearable={false} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Venue"></Select>
              </div>
            </div>
          </div>
          {#if activity_list.length > 0}
             <div class="field">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Name</label>
              <div class="control">
                <input class="input is-primary" bind:value={formModel.name} type="text" placeholder="{item} Name" required minlength="6">
              </div>
            </div>
            <div class="field">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Start</label>
              <div class="control">
                <input class="input is-primary" bind:value={formModel.start_date} type="datetime-local" placeholder="{item} Start" required>
              </div>
            </div>
            <div class="field">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Finish</label>
              <div class="control">
                <input class="input is-primary" bind:value={formModel.finish_date} type="datetime-local" placeholder="{item} Finish" required>
              </div>
            </div>
            <div class="field">
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="label">Activities</label>
              <div class="control">
              </div>
            </div>
            <div class="box">
            <div class="field">
              {#if formModel.activities.length > 0}
              <table class="table is-narrow is-fullwidth">
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th></th>
                </tr>
                {#each formModel.activities as sa, i}
                <tr>
                <td>{sa.name}</td>
                <td>R {sa.price}</td>
                <td><button class="button is-small" type="button" on:click={() => removeActivity(i)}>
                  <span class="icon is-small">
                    <i class="fa fa-trash"></i>
                  </span>
                </button></td>
                </tr>
                {/each}
              </table>  
              {:else}
              <div class="notification is-warning is-light">
                Please Add Activities
              </div>
              {/if}
              </div>
            <div class="field is-grouped">
              <p class="control is-expanded">
                <Select items={activity_list} bind:selectedValue={activity} listAutoWidth={false} containerClasses="is-fullwidth" placeholder="Please Select Activity"></Select>
              </p>
              <p class="control">
                <input class="input" type="number" bind:value={activity_price} placeholder="Activity Price" step="0.01" />
              </p>
              <p class="control">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="button is-info" on:click={addActivity}>
                  Add
                </a>
              </p>
            </div>
            </div>
            <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => formModel.enabled = !formModel.enabled}>
              {#if formModel.enabled}
                <p class="control">
                  <span class="icon">
                    <i class="fa fa-check-square fa-2x"></i>
                  </span>   
                </p>
                <p class="control" style="margin-left: 10px; font-size: large;"> Enabled</p>  
                {:else}
                <p class="control">
                  <span class="icon">
                    <i class="fa fa-square fa-2x"></i>
                  </span>   
                </p>
                <p class="control" style="margin-left: 10px; font-size: large;"> Disabled</p>  
                {/if}
            </div>  
            <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => formModel.email = !formModel.email}>
              {#if formModel.email}
                <p class="control">
                  <span class="icon">
                    <i class="fa fa-check-square fa-2x"></i>
                  </span>   
                </p>
                <p class="control" style="margin-left: 10px; font-size: large;"> Emails</p>  
                {:else}
                <p class="control">
                  <span class="icon">
                    <i class="fa fa-square fa-2x"></i>
                  </span>   
                </p>
                <p class="control" style="margin-left: 10px; font-size: large;"> Emails</p>  
                {/if}
            </div>  
            <div class="field has-addons" style="cursor:pointer;margin-top: 20px;margin-bottom: 20px;" on:click={() => formModel.live = !formModel.live}>
              {#if formModel.live}
                <p class="control">
                  <span class="icon">
                    <i class="fa fa-check-square fa-2x"></i>
                  </span>   
                </p>
                <p class="control" style="margin-left: 10px; font-size: large;"> Show Results</p>  
              {:else}
              <p class="control">
                <span class="icon">
                  <i class="fa fa-square fa-2x"></i>
                </span>   
              </p>
              <p class="control" style="margin-left: 10px; font-size: large;"> Show Results</p>  
              {/if}
            </div>  
            <div class="field is-grouped">
              <div class="control">
                <button id="btn-form-submit" class="button is-primary" type="submit">Save</button>
              </div>
              <div class="control">
                <button type="button" class="button is-primary is-light" on:click={() => cancelForm()}>Cancel</button>
              </div>
            </div>
          {:else}
             <div class="notification is-warning is-light">
              Please select a venue  
            </div>
          {/if}
         
        </form>
      {:else}
          {#if items_total > 0}
            <SvelteTable columns={columns} rows={items_data} classNameTable={['table is-hoverable is-narrow']} classNameSelect={['is-selected']}>
            <tr slot="row" let:row let:n>
                <td>{row.name}</td>
                <td>{row.venue_name}</td>
                <td style="text-align: center;">
                  {#if row.enabled}
                    <span class="has-text-success icon">
                      <i class="fa fa-check-square fa-2x"></i>
                    </span>
                  {:else}
                  <span class="has-text-danger icon">
                    <i class="fa fa-square fa-2x"></i>
                  </span>
                  {/if}
                </td>
                <td style="text-align: center;">{convertDateTime(row.start_date,2)}</td>
                <td style="text-align: center;">
                  <!-- id, name, start_date, finish_date, enabled, email, live ,venue_id, company_id, activities -->
                  <button class="button is-small is-primary" on:click={() => selectItem(row.id, row.name, row.start_date, row.finish_date, row.enabled, row.email, row.live, row.venue_id, row.venue_name, row.company_id, row.activities)}>
                    <span class="icon is-small">
                      <i class="fa fa-edit"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </SvelteTable>  
          {:else}
            <div class="notification is-warning">
              No Events Loaded    
            </div>
          {/if}
      {/if}
    {/if}
  </div>
</div>

