<script>
  import { Route, navigateTo, Navigate, routeIsActive } from "svelte-router-spa";
  import client from "../../client";
  import user from "../../stores/user";
  import { onMount } from 'svelte';
  
  onMount(() => {
    var burger = document.querySelector(".burger");
    var menu = document.querySelector("#" + burger.dataset.target);
    burger.addEventListener("click", function () {
      burger.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });
  });

  export let currentRoute;
  const params = { user:$user.name };

  async function logout() {
    console.log("Logging out");
    await client.logout();
    navigateTo('/');
    user.reset();
  }

  function looseFocus() {
    document.querySelector('#navbar-setup-link').classList.remove("is-hoverable");
    setTimeout(function () {
      document.querySelector('#navbar-setup-link').classList.add("is-hoverable");
      }, 100);
  }

</script>
  
<!-- START NAV -->
<nav class="navbar is-white">
  <div class="container">
      <div class="navbar-brand">
          <a class="navbar-item brand-text" href="/admin">
    Revolution
  </a>
          <div class="navbar-burger burger" data-target="navMenu">
              <span></span>
              <span></span>
              <span></span>
          </div>
      </div>
      <div id="navMenu" class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item" href="/admin" class:active={routeIsActive('/admin/index')}>Dashboard</a>
            <div class="navbar-item has-dropdown is-hoverable" id="navbar-setup-link">
              <!-- svelte-ignore a11y-invalid-attribute -->
              <a class="navbar-link" href="" class:active={(routeIsActive('/admin/athlete')||routeIsActive('/admin/venues')||routeIsActive('/admin/activity'||routeIsActive('/admin/events')))?true:false}>
                Setup
              </a>
              <div class="navbar-dropdown">
                <a class="navbar-item" href="/admin/athletes" on:click={() => looseFocus()}>Athletes</a>
                <a class="navbar-item" href="/admin/venues" on:click={() => looseFocus()}>Venues</a>
                <a class="navbar-item" href="/admin/activity" on:click={() => looseFocus()}>Activities</a>
                <a class="navbar-item" href="/admin/events" on:click={() => looseFocus()}>Events</a>
                <a class="navbar-item" href="/admin/stage" on:click={() => looseFocus()}>Stage Race</a>
              </div>
            </div>
            <a class="navbar-item" href="/admin/import" class:active={routeIsActive('/admin/import')}>Pre-entries</a>
            <a class="navbar-item" href="/admin/export" class:active={routeIsActive('/admin/export')}>Export</a>
            <a class="navbar-item" href="/admin/finalise" class:active={routeIsActive('/admin/finalise')}>Finalise</a>
            <a class="navbar-item" href="/admin/challenge" class:active={routeIsActive('/admin/challenge')}>Challenges</a>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <span class="tag is-dark">{$user.name} {$user.surname}</span>
            </div>
            <div class="navbar-item">
              <div class="buttons">
                <!-- svelte-ignore a11y-invalid-attribute -->
                <a class="button is-small is-light" href="" on:click={() => logout()}>
                  Log out
                </a>
              </div>
            </div>
          </div>
      </div>
  </div>
</nav>
<!-- END NAV -->
<div class="container is-fluid">
  <div class="columns">
    <Route {currentRoute} {params} />
  </div>
</div>
