<script>
  export let currentRoute = {currentRoute};
  export let params = {params};
</script>

<style>
  .big-icon {
    font-size: 200px;
  }
</style>

<div class="hero is-fullheight is-primary">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="column is-8 is-offset-2">
        <h1>
          <span class="icon">
            <i class="fa fa-frown-o big-icon"></i>
          </span>
        </h1>
        <div class="content is-large" style="margin-top: 1rem">
          <div class="box">
            <h1>404 Page Not Found</h1>
            <p>
              Sorry, we can't seem to find what you're looking for. You've
              landed on a URL that doesn't seem to exist.
            </p>
          </div>
        </div>
        <a href="/"
          ><span class="icon"><i class="fa fa-home fa-2x"></i></span> Home</a
        >
      </div>
    </div>
  </div>
</div>
