export function firstLetterAndLetterAfterSpaceUpper(convert) {
  var splitStr = convert.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function validateEmail(mail) {
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export function validateScanCode(scan) {
  // var rvRegexp = /TA([0-9]+)/g;
  var rvRegexp = /RT([0-9]+)/g;
  var match = rvRegexp.exec(scan);
  if (match != null && match.length > 1) {
    return true;
  } else {
    return false;
  }
}

export function compareNumberStringDistance(a, b) {
  try {
    return parseInt(a.name.toLowerCase().split("km")[0]) - parseInt(b.name.toLowerCase().split("km")[0]);
  } catch(err) {
    return -1;
  }
}
