export function category(age) {
  if (age <= 11) {
    return "Under 11";
  } else if (age > 11 && age < 16) {
    return "12 to 15";
  } else if (age > 15 && age < 19) {
    return "16 to 18";
  } else if (age > 18 && age < 30) {
    return "Senior (19-29)";
  } else if (age > 29 && age < 40) {
    return "Sub-Veteran (30-39)";
  } else if (age > 39 && age < 50) {
    return "Veteran (40-49)";
  } else if (age > 49 && age < 60) {
    return "Master (50-59)";
  } else if (age > 59) {
    return "Grand-Master (60+)";
  } else if (age > 69) {
    return "Great-Grand-Master (70+)";
  }

}
